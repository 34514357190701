import * as React from 'react';
import { useGetCompanies, useGetCompany, useUpdateCompany } from '@sity-ai/api';
import type { Company } from '@sity-ai/types';
import { useQueryClient } from '@tanstack/react-query';

import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface CompaniesResponse {
  companies: Company[];
  loading: boolean;
  error: Error | null;
  refresh: () => void;
}

interface CompanyResponse {
  company: Company | null;
  loading: boolean;
  error: Error | null;
  refresh: () => void;
  update: (newCompany: Company) => Promise<Company>;
}

export function useCompanies(query: string): CompaniesResponse {
  const { token } = useUserContext();

  // Use last load time to invalidate cache
  const initialLoadTime = new Date().getTime();
  const [lastLoaded, setLastLoaded] = React.useState<number>(initialLoadTime);

  // Fetch data for all companies
  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError,
  } = useGetCompanies({ input: query }, token, { queryKey: [query, lastLoaded] });

  // Refresh company data
  const refresh = React.useCallback((): void => {
    const now = new Date().getTime();
    setLastLoaded(now);
  }, []);

  return {
    companies,
    loading: companiesLoading || false,
    error: companiesError || null,
    refresh,
  };
}

export function useCompany(providedCompanyID?: number): CompanyResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const defaultCompanyID = user?.companyID?.toString() || '';
  const companyID = providedCompanyID?.toString() || defaultCompanyID;

  // Fetch company data; use react-query to invalidate cache as appropriate
  const {
    data: company,
    isLoading: companyLoading,
    error: getCompanyError,
  } = useGetCompany({ companyID }, token, { queryKey: [companyID], enabled: !!companyID && !!token });

  const refresh = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getCompany'] });
  };

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: updateCompany,
    isLoading: updateCompanyLoading,
    error: updateCompanyError,
  } = useUpdateCompany({
    onSuccess: refresh,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/
  const update = async (newCompany: Company): Promise<Company> => {
    return await updateCompany({ params: { companyID }, company: newCompany, token });
  };

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const loading = React.useMemo(() => {
    return companyLoading || updateCompanyLoading;
  }, [companyLoading, updateCompanyLoading]);
  const error = React.useMemo(() => {
    return getCompanyError || updateCompanyError;
  }, [getCompanyError, updateCompanyError]);

  return {
    company,
    loading,
    error,
    refresh,
    update,
  };
}
