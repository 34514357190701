import * as React from 'react';
import type { PricebookItem } from '@sity-ai/types';

import { usePricebookItems, type PricebookItemsResponse } from '@/hooks/use-pricebook-items';

import { Loading } from '@/components/core/loading';

/*****************************************************************************
 * Types
 *****************************************************************************/
export interface PricebookItemContextValue extends PricebookItemsResponse {
  selectedPricebookItems: PricebookItem[];
  selectPricebookItem: (pricebookItem: PricebookItem) => void;
  clearSelectedPricebookItem: () => void;
}
interface PricebookItemsProviderProps {
  children: React.ReactNode;
  pricebookId?: number;
}

/*****************************************************************************
 * Context
 *****************************************************************************/
export const PricebookItemsContext = React.createContext<PricebookItemContextValue>({
  copyItem: async () => {
    throw new Error('Not implemented');
  },
  error: null,
  loading: false,
  moveItems: async () => false,
  pricebookItems: [],
  refreshPricebookItems: async () => undefined,
  updateItemsStatus: async () => false,
  updateItem: async () => ({}) as PricebookItem,
  createItem: async () => ({}) as PricebookItem,
  deleteItem: async () => false,
  selectedPricebookItems: [],
  selectPricebookItem: () => undefined,
  clearSelectedPricebookItem: () => undefined,
});
export function usePricebookItemsContext(): PricebookItemContextValue {
  const context = React.useContext(PricebookItemsContext);
  if (!context) {
    throw new Error('usePricebookItemsContext must be used within a PricebookItemsProvider');
  }
  return context;
}

/*****************************************************************************
 * Provider
 *****************************************************************************/
export function PricebookItemsProvider({
  children,
  pricebookId: initialPricebookId = 0,
}: PricebookItemsProviderProps): React.JSX.Element {
  const pricebookItemsHook = usePricebookItems();

  const [selectedPricebookItems, setSelectedPricebookItems] = React.useState<PricebookItem[]>([]);
  const selectPricebookItem = (pricebookItem: PricebookItem): void => {
    setSelectedPricebookItems([...selectedPricebookItems, pricebookItem]);
  };
  const clearSelectedPricebookItem = (): void => {
    setSelectedPricebookItems([]);
  };

  React.useEffect(() => {
    if (initialPricebookId && pricebookItemsHook.pricebookItems.length > 0) {
      const pricebookItems = pricebookItemsHook.pricebookItems.filter(
        (item) => item.pricebookID === initialPricebookId
      );
      setSelectedPricebookItems(pricebookItems);
    }
  }, [initialPricebookId, pricebookItemsHook.pricebookItems]);

  const contextValue = React.useMemo(
    () => ({
      ...pricebookItemsHook,
      selectedPricebookItems,
      selectPricebookItem,
      clearSelectedPricebookItem,
    }),
    [pricebookItemsHook, selectedPricebookItems, selectPricebookItem, clearSelectedPricebookItem]
  );

  return (
    <PricebookItemsContext.Provider value={contextValue}>
      {pricebookItemsHook.loading ? <Loading /> : children}
    </PricebookItemsContext.Provider>
  );
}
