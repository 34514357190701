import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Buildings as BuildingIcon } from '@phosphor-icons/react/Buildings';
import { CreditCard as CreditCardIcon } from '@phosphor-icons/react/CreditCard';
import { LockKey as LockKeyIcon } from '@phosphor-icons/react/LockKey';
import { User as UserIcon } from '@phosphor-icons/react/User';
import { Users as UsersIcon } from '@phosphor-icons/react/Users';

import { config } from '@/config';

import { paths } from '@/paths';

import { AuthStrategy } from '@/lib/auth/strategy';
import { Roles } from '@/constants/roles';

import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { useAuthorization } from '@/hooks/authorization/use-authorization';
import { useUser } from '@/hooks/use-user';

import getUserFullName from '@/utils/get-user-full-name';

import { RouterLink } from '@/components/core/link';
import { Auth0SignOut } from '@/components/hub/layout/user-popover/auth0-sign-out';

export interface UserPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open: boolean;
}

// Function to invert the roles object
const invertRoles = (rolesObj: Record<string, number>): Record<number, string> => {
  return Object.keys(rolesObj).reduce((acc: Record<number, string>, key: string) => {
    acc[rolesObj[key]] = key;
    return acc;
  }, {});
};

const invertedRoles = invertRoles(Roles);

export function UserPopover({ anchorEl, onClose, open }: UserPopoverProps): React.JSX.Element {
  const { user } = useUser();
  const { roles } = useUserContext();
  const { hasPermission } = useAuthorization();

  const userRoleLevel = Math.min(...roles);
  const roleName = invertedRoles[userRoleLevel]?.replace('FieldSity_', '') ?? 'Unknown';
  const chipColor = roleName === 'Admin' ? 'primary' : undefined;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={onClose}
      open={Boolean(open)}
      slotProps={{ paper: { sx: { width: '280px' } } }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Box sx={{ p: 2 }}>
        <Typography>{getUserFullName(user)}</Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.email}
        </Typography>
        <Chip color={chipColor} label={roleName} size="small" variant="soft" />
      </Box>
      <Divider />
      <List sx={{ p: 1 }}>
        <MenuItem component={RouterLink} href={paths.settings.account} onClick={onClose}>
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          My profile
        </MenuItem>
        <MenuItem component={RouterLink} href={paths.settings.security} onClick={onClose}>
          <ListItemIcon>
            <LockKeyIcon />
          </ListItemIcon>
          Security
        </MenuItem>
        <MenuItem component={RouterLink} href={paths.settings.company} onClick={onClose}>
          <ListItemIcon>
            <BuildingIcon />
          </ListItemIcon>
          Company profile
        </MenuItem>
        {hasPermission('billing', 'read') ? (
          <MenuItem component={RouterLink} href={paths.settings.billing} onClick={onClose}>
            <ListItemIcon>
              <CreditCardIcon />
            </ListItemIcon>
            Billing
          </MenuItem>
        ) : null}
        {hasPermission('user', 'update') ? (
          <MenuItem component={RouterLink} href={paths.settings.team} onClick={onClose}>
            <ListItemIcon>
              <UsersIcon />
            </ListItemIcon>
            Manage team
          </MenuItem>
        ) : null}
      </List>
      <Divider />
      <Box sx={{ p: 1 }}>{config.auth.strategy === AuthStrategy.AUTH0 ? <Auth0SignOut /> : null}</Box>
    </Popover>
  );
}
