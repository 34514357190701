import * as React from 'react';
import { initialize } from 'launchdarkly-js-client-sdk';
import type { LDClient } from 'launchdarkly-js-client-sdk';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

// Types
export interface FlagContextValue {
  ldClient: LDClient | undefined;
}
export interface FlagProviderProps {
  children: React.ReactNode;
}

// Context Exports
export const FlagsContext = React.createContext<FlagContextValue>({
  ldClient: undefined,
});

export function useFlagContext(): FlagContextValue {
  return React.useContext(FlagsContext);
}

export function FlagProvider({ children }: FlagProviderProps): React.JSX.Element {
  const [ldClient, setLdClient] = React.useState<LDClient>();
  const { user } = useUserContext();

  React.useEffect(() => {
    if (user) {
      const launchUser = {
        kind: 'user',
        key: String(user.userID), // Unique identifier for the user
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      };
      const client = initialize(import.meta.env.VITE_LAUNCHDARKLY_KEY, launchUser);
      client.on('ready', () => {
        setLdClient(client);
      });
    }
  }, [user]);

  return <FlagsContext.Provider value={{ ldClient }}>{children}</FlagsContext.Provider>;
}
