import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { paths } from '@/paths';

import { ClientsProvider } from '@/contexts/clients-context';
import { SchedulingProvider } from '@/contexts/scheduling-context';
import { ServiceRecordsProvider } from '@/contexts/service-records-context';

import { logError } from '@/utils/log-error';

import { ErrorFallback } from '@/components/core/error-fallback';
import { Layout } from '@/components/hub/layout/layout';
import { ProtectedPage } from '@/components/hub/layout/protected-page';

export const route: RouteObject = {
  element: (
    <Layout>
      <Outlet />
    </Layout>
  ),
  children: [
    {
      path: 'calendar',
      index: true,
      lazy: async () => {
        const { Page } = await import('@/pages/calendar');

        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2, 3]}>
                  <ClientsProvider>
                    <ServiceRecordsProvider>
                      <SchedulingProvider>
                        <Page />
                      </SchedulingProvider>
                    </ServiceRecordsProvider>
                  </ClientsProvider>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
  ],
};
