import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useCompanyContext } from '@/contexts/company-context';

export interface WorkspacesPopoverProps {
  anchorEl: null | Element;
  onChange?: (tenant: string) => void;
  onClose?: () => void;
  open?: boolean;
}

export function WorkspacesPopover({
  anchorEl,
  onChange,
  onClose,
  open = false,
}: WorkspacesPopoverProps): React.JSX.Element {
  const { company } = useCompanyContext();
  const companyName = company?.name || '';

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={onClose}
      open={open}
      slotProps={{ paper: { sx: { width: '250px' } } }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <MenuItem
        onClick={() => {
          onChange?.(companyName);
        }}
      >
        {companyName}
      </MenuItem>
    </Menu>
  );
}
