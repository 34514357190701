import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TiktokLogo,
  TwitterLogo,
  YoutubeLogo,
} from '@phosphor-icons/react/dist/ssr';

interface FooterProps {
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const socialIcons = [
  { icon: TwitterLogo, href: 'https://twitter.com/SityPlatform' },
  { icon: FacebookLogo, href: 'https://www.facebook.com/profile.php?id=61563606718769' },
  { icon: InstagramLogo, href: 'https://instagram.com/sityplatform' },
  { icon: YoutubeLogo, href: 'https://www.youtube.com/channel/UCGFUFThGJver4Jnbrte6ZDA' },
  { icon: LinkedinLogo, href: 'https://www.linkedin.com/company/sityplatform' },
  { icon: TiktokLogo, href: 'https://tiktok.com/@sityplatform' },
];

const footerLinks = [
  { label: 'Terms', href: 'https://fieldsity.com/terms', isExternal: true },
  { label: 'Privacy', href: 'https://fieldsity.com/privacy', isExternal: true },
  { label: 'Security', href: '/security', isExternal: false },
  { label: 'Contact', href: '/contact', isExternal: false },
];

export function Footer({ maxWidth = 'lg' }: FooterProps): React.JSX.Element {
  return (
    <Box
      component="footer"
      sx={{
        borderTop: '1px solid',
        borderColor: 'divider',
        py: { xs: 2, md: 2 },
        bgcolor: 'background.paper',
        mt: 8,
      }}
    >
      <Container
        maxWidth={maxWidth}
        sx={{
          '--Content-margin': '0 auto',
          '--Content-maxWidth': 'var(--maxWidth-xl)',
          '--Content-paddingX': '24px',
          '--Content-width': '100%',
        }}
      >
        {/* Main Stack */}
        <Stack
          alignItems={{ xs: 'center', md: 'center' }}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          spacing={{ xs: 2, md: 2 }}
        >
          {/* Logo & Copyright */}
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{
              mb: { xs: 0, md: 0 },
            }}
          >
            <Box
              alt="Fieldsity"
              component="img"
              src="/assets/footer-logo.svg"
              sx={{
                height: 32,
                width: 'auto',
              }}
            />
            <Box
              component="span"
              sx={{
                color: 'text.secondary',
                fontSize: '0.875rem',
              }}
            >
              © {new Date().getFullYear()} Sity Global, Inc.
            </Box>
          </Stack>

          {/* Footer Links */}
          <Stack
            component="nav"
            direction="row"
            spacing={3}
            sx={{
              mb: { xs: 1, md: 0 },
            }}
          >
            {footerLinks.map((link) => (
              <Link
                color="text.secondary"
                href={link.href}
                key={link.label}
                rel={link.isExternal ? 'noopener noreferrer' : undefined}
                sx={{
                  fontSize: '0.875rem',
                  '&:hover': { color: 'text.primary' },
                }}
                target={link.isExternal ? '_blank' : undefined}
                underline="hover"
              >
                {link.label}
              </Link>
            ))}
          </Stack>

          {/* Social Icons */}
          <Stack direction="row" spacing={2}>
            {socialIcons.map(({ icon: Icon, href }) => (
              <Link
                href={href}
                key={href}
                rel="noopener noreferrer"
                sx={{
                  color: 'text.secondary',
                  '&:hover': { color: 'text.primary' },
                  display: 'flex',
                  alignItems: 'center',
                }}
                target="_blank"
              >
                <Icon size={20} weight="regular" />
              </Link>
            ))}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
