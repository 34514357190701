import * as React from 'react';

import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { Permissions } from './types';
import checkPermission from './check-permission';

export function useAuthorization() {
    const { user, roles } = useUserContext();

    const hasPermission = React.useCallback((
        resource: keyof Permissions,
        action: Permissions[keyof Permissions]["action"],
        data?: Permissions[keyof Permissions]["dataType"]
    ) => {
        if (!user) return false;
        return checkPermission(user, roles, resource, action, data);
    }, [user, roles]);

    return { hasPermission };
}
