import * as React from 'react';
import type { StripeCustomerSubscription } from '@sity-ai/api';
import type { Company } from '@sity-ai/types';
import { Navigate, useLocation } from 'react-router-dom';

import { paths } from '@/paths';

import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { useAuthorization } from '@/hooks/authorization/use-authorization';
import { useCompany } from '@/hooks/use-companies';
import useIntercom from '@/hooks/use-intercom';
import { useStripeSubscription } from '@/hooks/use-stripe-subscription';

import { isSubscriptionCanceled } from '@/utils/stripe-subscription/cancelation';

import { Loading } from '@/components/core/loading';
import { toast } from '@/components/core/toaster';
import Paywall from '@/components/paywall/index';

export interface CompanyContextValue {
  company: Company | null;
  subscription: StripeCustomerSubscription | null;
  updateCompanyTimezone?: (timezone: string) => Promise<void>;
  companyTimezone: string;
}

export const CompanyContext = React.createContext<CompanyContextValue>({
  company: null,
  subscription: null,
  companyTimezone: 'UTC',
});

export interface CompanyProviderProps {
  children: React.ReactNode;
}

export function useCompanyContext(): CompanyContextValue {
  return React.useContext(CompanyContext);
}

export function CompanyProvider({ children }: CompanyProviderProps): React.JSX.Element {
  const location = useLocation();
  const { user, isLoading: userLoading } = useUserContext();
  const { hasPermission } = useAuthorization();
  const { company, loading: companyLoading, refresh, update: updateCompanyDetails } = useCompany();
  const { subscription: stripeData, loading: subscriptionLoading, loaded } = useStripeSubscription(company);
  const { loading: intercomLoading } = useIntercom(company);

  const loading = companyLoading || subscriptionLoading || intercomLoading || userLoading;
  const subscriptionStatus = isSubscriptionCanceled(stripeData);

  const companyTimezone = React.useMemo(() => {
    return company?.timeZone || 'UTC';
  }, [company?.timeZone]);

  const companyEditPermission = hasPermission('company', 'update');

  const updateCompanyTimezone = React.useCallback(
    async (timezone: string) => {
      if (!company || !companyEditPermission || !updateCompanyDetails) return;
      try {
        await updateCompanyDetails({ ...company, timeZone: timezone });
        refresh();
        toast.success('Company timezone updated successfully');
      } catch (error) {
        toast.error('Failed to update company timezone');
        throw error;
      }
    },
    [company, companyEditPermission, updateCompanyDetails, refresh]
  );

  const contextValue = React.useMemo(
    () => ({
      company,
      subscription: stripeData,
      updateCompanyTimezone: companyEditPermission ? updateCompanyTimezone : undefined,
      companyTimezone,
    }),
    [company, stripeData, companyEditPermission, updateCompanyTimezone, companyTimezone]
  );

  const companyIsOnboarded = (company?.onboarded ?? true) as boolean;
  const isOnboardingRoute = location.pathname === paths.quickstart || location.pathname === paths.adminOnboarding;
  const userIsOnboarded = [user?.firstName, user?.lastName, user?.contactNumber, user?.jobTitle].every((value) =>
    Boolean(value)
  );
  const isUserOnboardingRoute = location.pathname === paths.quickstart || location.pathname === paths.userOnboarding;

  return (
    <CompanyContext.Provider value={contextValue}>
      {loading ? (
        <Loading />
      ) : loaded && subscriptionStatus.canceled ? (
        <Paywall onClose={refresh} open />
      ) : !companyIsOnboarded && !isOnboardingRoute ? (
        <Navigate to={paths.adminOnboarding} />
      ) : companyIsOnboarded && !!user && !userIsOnboarded && !isUserOnboardingRoute ? (
        <Navigate to={paths.userOnboarding} />
      ) : (
        children
      )}
    </CompanyContext.Provider>
  );
}

export const CompanyConsumer = CompanyContext.Consumer;
