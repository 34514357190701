import { useEffect, useState } from 'react';
import { useGetUserHash } from '@sity-ai/api';
import type { Company } from '@sity-ai/types';
import { useIntercom } from 'react-use-intercom';

import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface IntercomResponse {
  initialized: boolean;
  loading: boolean;
  error: Error | null;
}

const useIntercomHook = (company: Company | null): IntercomResponse => {
  const { boot } = useIntercom();
  const { user, token } = useUserContext();

  const email = user?.email || '';
  const companyID = company?.companyID.toString() || '';

  const {
    data: userHash,
    isLoading: fetchingUserHash,
    error: fetchingUserHashError,
  } = useGetUserHash({ email, companyID }, token, {
    enabled: !!email && !!companyID,
    queryKey: [email, companyID],
  });

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (userHash) {
      boot({
        name: `${user!.firstName} ${user!.lastName}`,
        userId: user?.userID?.toString() ?? '',
        email,
        userHash,
        company: {
          companyId: companyID,
          name: company!.name,
          createdAt: company!.createdAt,
        },
      });

      setInitialized(true);
    }
  }, [boot, company, userHash, user]);

  useEffect(() => {
    if (fetchingUserHashError) {
      setInitialized(false);
      logger.error(fetchingUserHashError);
    }
  }, [fetchingUserHashError]);

  return {
    loading: fetchingUserHash || false,
    error: fetchingUserHashError || null,
    initialized,
  };
};

export default useIntercomHook;
