import type { Components, Theme as MuiTheme } from '@mui/material/styles';

import type { Theme } from '../types';

export const MuiButtonGroup = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }: { theme: MuiTheme }) => ({
      bgcolor: 'transparent',
      borderRadius: 8,
      padding: 0,
      boxShadow: 'none',
      '& .MuiButton-root': {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 0,
        minWidth: '80px',
        px: 2.5,
        py: 1,
        fontSize: '0.875rem',
        fontWeight: 500,
        color: theme.palette.text.secondary,
        position: 'relative',
        transition: theme.transitions.create(['background-color', 'color', 'border-color'], {
          duration: theme.transitions.duration.short,
        }),
        '&:first-of-type': {
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        },
        '&:last-of-type': {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
        },
        '&:not(:last-of-type)': {
          borderRight: 'none',
        },
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.text.primary,
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.text.primary,
          color: theme.palette.common.white,
          fontWeight: 600,
          '&:hover': {
            backgroundColor: theme.palette.text.primary,
          },
        },
        '&:focus': {
          outline: 'none',
          zIndex: 1,
        },
      },
    }),
  },
} satisfies Components<Theme>['MuiButtonGroup'];
