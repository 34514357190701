// components/core/public-route.tsx
import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { paths } from '@/paths';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

import { Loading } from '@/components/core/loading';

interface PublicRouteProps {
  children: React.ReactNode;
  requireToken?: boolean;
  token?: string | null;
}

export function PublicRoute({ children, requireToken = false, token }: PublicRouteProps): JSX.Element {
  const { user, isLoading } = useUserContext();

  if (isLoading) {
    return <Loading />;
  }

  // If user is authenticated, redirect to dashboard
  if (user) {
    return <Navigate replace to={paths.home} />;
  }

  // If route requires token but none provided, redirect to home
  if (requireToken && !token) {
    return <Navigate replace to={paths.home} />;
  }

  return <>{children}</>;
}
