import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import { CaretDown, User } from '@phosphor-icons/react';
import { List as ListIcon } from '@phosphor-icons/react/List';
import { useNavigate } from 'react-router-dom';

import { paths } from '@/paths';

import { IntegrationsProvider } from '@/contexts/integrations-context';
import { usePopover } from '@/hooks/use-popover';

import { ThemeToggleButton } from '@/components/core/theme-toggle-button';
import TrialStatus from '@/components/core/trial-status/trial-status';
import { layoutConfig } from '@/components/hub/layout/config';
import { MobileNav } from '@/components/hub/layout/nav/mobile-nav';
import { createMenuItems } from '@/components/hub/layout/nav/nav-config';
import { QuickBooksToolbar } from '@/components/hub/layout/nav/qbo-toolbar';
import { SearchButton } from '@/components/hub/layout/nav/search-button';
import { UserPopover } from '@/components/hub/layout/user-popover/user-popover';

const SIDEBAR_WIDTH = 74;

function UserButton(): React.JSX.Element {
  const popover = usePopover<HTMLButtonElement>();

  return (
    <React.Fragment>
      <Box
        component="button"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          border: 'none',
          background: 'transparent',
          cursor: 'pointer',
          p: 0,
        }}
      >
        <Badge
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          color="success"
          sx={{
            '& .MuiBadge-dot': {
              border: '2px solid var(--MainNav-background)',
              borderRadius: '50%',
              bottom: '6px',
              height: '12px',
              right: '6px',
              width: '12px',
            },
          }}
          variant="dot"
        >
          <Avatar
            sx={{
              height: 28,
              width: 28,
            }}
          >
            <SvgIcon>
              <User size={24} />
            </SvgIcon>
          </Avatar>
        </Badge>
      </Box>
      <UserPopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} />
    </React.Fragment>
  );
}

export function UnifiedTopNav(): React.JSX.Element {
  const navigate = useNavigate();
  const createMenu = usePopover();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const handleMouseEnter = (): void => {
    createMenu.handleOpen();
  };

  const handleMouseLeave = (): void => {
    createMenu.handleClose();
  };

  return (
    <Box
      component="header"
      sx={{
        position: 'fixed',
        top: 0,
        left: { xs: 0, sm: `${SIDEBAR_WIDTH}px` },
        backgroundColor: 'background.paper',
        borderBottom: '1px solid',
        borderColor: 'divider',
        width: { xs: '100%', sm: `calc(100% - ${SIDEBAR_WIDTH}px)` },
        height: '56px',
        zIndex: (theme) => theme.zIndex.appBar + 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pl: 2,
        pr: 3,
        boxSizing: 'border-box',
      }}
    >
      {/* Hamburger menu aligned left on small screens */}
      <IconButton
        onClick={() => {
          setMobileNavOpen(true);
        }}
        sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
      >
        <ListIcon />
      </IconButton>

      {/* Only show Create and Schedule buttons on medium screens and above */}
      <Stack direction="row" spacing={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Button
            color="secondary"
            endIcon={<CaretDown />}
            ref={createMenu.anchorRef as React.RefObject<HTMLButtonElement>}
          >
            Create
          </Button>
          <Menu
            MenuListProps={{
              onMouseEnter: handleMouseEnter,
              onMouseLeave: handleMouseLeave,
            }}
            anchorEl={createMenu.anchorRef.current}
            onClose={createMenu.handleClose}
            open={createMenu.open}
          >
            {createMenuItems.map((item) => (
              <MenuItem
                key={item.key}
                onClick={() => {
                  navigate(item.href);
                  createMenu.handleClose();
                }}
                sx={{
                  py: 1.5,
                  px: 3,
                  fontSize: '0.95rem',
                }}
              >
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Button
          color="secondary"
          onClick={() => {
            navigate(paths.calendar);
          }}
        >
          Schedule
        </Button>
      </Stack>

      {/* Right-aligned icons and user popover */}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          flexGrow: 1,
          justifyContent: { xs: 'flex-end', sm: 'flex-end' },
          alignItems: 'center',
        }}
      >
        <TrialStatus />

        {/* Hide QuickBooksToolbar and SearchButton on mobile */}
        <Stack direction="row" spacing={2} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <IntegrationsProvider>
            <QuickBooksToolbar />
          </IntegrationsProvider>{' '}
          <SearchButton />
        </Stack>

        {/* Always show the user popover */}
        <ThemeToggleButton />
        <UserButton />

        {/* MobileNav drawer with navigation items passed in */}
        <MobileNav
          items={layoutConfig.navItems} // Pass navigation items here
          onClose={() => {
            setMobileNavOpen(false);
          }}
          open={mobileNavOpen}
        />
      </Stack>
    </Box>
  );
}
