import * as React from 'react';

import { UnifiedLayout } from '@/components/hub/layout/nav/unified-layout';

interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps): React.ReactElement {
  return <UnifiedLayout>{children}</UnifiedLayout>;
}
