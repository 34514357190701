import { route as adminOnboarding } from '@/routes/admin-onboarding';
import { route as authRoute } from '@/routes/auth';
import { route as calendarRoute } from '@/routes/calendar';
import { route as clientsRoute } from '@/routes/clients';
import { route as hubRoute } from '@/routes/hub';
import { route as integrationsRoute } from '@/routes/integrations';
import { route as invoicesRoute } from '@/routes/invoices';
import { route as jobsRoute } from '@/routes/jobs';
import { route as joinRoute } from '@/routes/join';
import { route as pdfRoute } from '@/routes/pdf';
import { route as pricebookItemRoute } from '@/routes/pricebook';
import { route as quickstartRoute } from '@/routes/quick-start-guide';
import { route as settingsRoute } from '@/routes/settings';
import { route as userOnboarding } from '@/routes/user-onboarding';
import type { RouteObject } from 'react-router-dom';

import { Page as NotFoundPage } from '@/pages/not-found';

export const routes: RouteObject[] = [
  {
    path: 'errors',
    children: [
      {
        path: 'internal-server-error',
        lazy: async () => {
          const { Page } = await import('@/pages/errors/internal-server-error');
          return { Component: Page };
        },
      },
      {
        path: 'not-authorized',
        lazy: async () => {
          const { Page } = await import('@/pages/errors/not-authorized');
          return { Component: Page };
        },
      },
      {
        path: 'not-found',
        lazy: async () => {
          const { Page } = await import('@/pages/errors/not-found');
          return { Component: Page };
        },
      },
    ],
  },
  authRoute,
  calendarRoute,
  hubRoute,
  jobsRoute,
  settingsRoute,
  invoicesRoute,
  clientsRoute,
  pricebookItemRoute,
  pdfRoute,
  quickstartRoute,
  integrationsRoute,
  joinRoute,
  adminOnboarding,
  userOnboarding,
  { path: '*', element: <NotFoundPage /> },
];
