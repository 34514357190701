import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { SyncIcons } from '@/components/integrations/common/sync/icons';
import type { SyncDirection } from '@/components/integrations/common/sync/types';

interface SyncDirectionWithLogosProps {
  direction: SyncDirection;
  size?: number;
  sourceIcon: React.ReactNode;
  targetIcon: React.ReactNode;
  className?: string;
}

export function SyncDirectionWithLogos({
  direction,
  size = 24,
  sourceIcon,
  targetIcon,
  className,
}: SyncDirectionWithLogosProps): React.JSX.Element {
  const DirectionIcon = direction.type === 'one-way' ? SyncIcons.OneWay : SyncIcons.TwoWay;

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={1}
      sx={{
        '& .icon': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: size,
          height: size,
        },
      }}
    >
      <Box className="icon">{sourceIcon}</Box>
      <DirectionIcon className={className} size={size} />
      <Box className="icon">{targetIcon}</Box>
    </Stack>
  );
}
