import type * as React from 'react';

import { config } from '@/config';

import { AuthStrategy } from '@/lib/auth/strategy';

import { UserContext as Auth0UserContext, UserProvider as Auth0UserProvider } from './auth0/user-context';
import type { UserContextValue } from './types';

let UserProvider: React.FC<{ children: React.ReactNode }>;

let UserContext: React.Context<UserContextValue | undefined>;

switch (config.auth.strategy) {
  case AuthStrategy.AUTH0:
    UserContext = Auth0UserContext;
    UserProvider = Auth0UserProvider;
    break;
  default:
    throw new Error('Invalid auth strategy');
}

export { UserProvider, UserContext };
