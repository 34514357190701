import { AccountingSystemValue, Address } from '@sity-ai/types';

import { LeadSources } from '@/constants/lead-sources';

import { WorkingHoursField } from '@/components/core/form-fields/working-hours/types';

export type LeadSource = (typeof LeadSources)[number];

export interface Invitee {
  email: string;
  role: string;
  inviteUser?: boolean;
  isAdmin?: boolean;
  companyID?: number;
}

export interface AdminOnboardingWizardData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  jobTitle: string;
  termsAndConditions: boolean;
  companyName: string;
  companyAddress: Address;
  annualRevenue: number | undefined;
  industry: number | null;
  industryOther: string | null;
  companyHours: WorkingHoursField[];
  accountingSystem?: AccountingSystemValue;
  accountingSystemOther?: string;
  soloUse: boolean;
  teamSize: number | undefined;
  invitees: Invitee[];
  leadSource: number | null;
  leadSourceOther: string;
  currentStep: number;
  numSteps: number;
  onboarded: boolean;
}

export const AdminOnboardingWizardDataInitialValue: AdminOnboardingWizardData = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  jobTitle: '',
  termsAndConditions: false,
  companyName: '',
  companyAddress: {
    streetAddress: '',
    route: '',
    subpremise: '',
    city: '',
    stateID: '',
    postalCode: '',
  },
  annualRevenue: undefined,
  industry: null,
  industryOther: null,
  companyHours: [],
  accountingSystem: undefined,
  accountingSystemOther: '',
  soloUse: false,
  teamSize: undefined,
  invitees: [],
  leadSource: null,
  leadSourceOther: '',
  currentStep: 0,
  numSteps: 0,
  onboarded: false,
};
