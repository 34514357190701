import * as React from 'react';
import type { Invoice } from '@sity-ai/types';

import { useInvoices, type InvoicesResponse } from '@/hooks/use-invoices';

/*****************************************************************************
 * Types
 *****************************************************************************/
export interface InvoicesContextValue extends InvoicesResponse {
  selectedInvoice: Invoice | null;
  setSelectedInvoice: (invoice: Invoice | null) => void;
  filteredInvoices: Invoice[];
  setFilteredInvoices: (invoices: Invoice[]) => void;
}
export interface InvoicesProviderProps {
  children: React.ReactNode;
  invoiceID?: number;
  clientID?: number;
  jobID?: number;
}

/*****************************************************************************
 * Context
 *****************************************************************************/
export const InvoicesContext = React.createContext<InvoicesContextValue>({
  invoices: [],
  refreshInvoiceList: () => undefined,
  selectedInvoice: null,
  setSelectedInvoice: () => undefined,
  filteredInvoices: [],
  setFilteredInvoices: () => undefined,
  loading: false,
  error: null,
  grandTotal: 0,
  totalsByJob: {},
  createInvoice: () => Promise.resolve({} as Invoice),
  updateInvoice: () => Promise.resolve({} as Invoice),
});
export function useInvoicesContext(): InvoicesContextValue {
  return React.useContext(InvoicesContext);
}

/*****************************************************************************
 * Provider
 *****************************************************************************/
export function InvoicesProvider({ children, invoiceID, clientID, jobID }: InvoicesProviderProps): React.JSX.Element {
  const invoicesHook = useInvoices();

  const [selectedInvoice, setSelectedInvoice] = React.useState<Invoice | null>(null);
  const [filteredInvoices, setFilteredInvoices] = React.useState<Invoice[]>([]);

  React.useEffect(() => {
    if (invoiceID && invoicesHook.invoices.length > 0) {
      let filteredInvoices = invoicesHook.invoices;
      if (clientID) filteredInvoices = filteredInvoices.filter((invoice) => invoice.clientID === clientID);
      if (jobID) filteredInvoices = filteredInvoices.filter((invoice) => invoice.jobID === jobID);
      if (invoiceID) filteredInvoices = filteredInvoices.filter((invoice) => invoice.invoiceID === invoiceID);
      setFilteredInvoices(filteredInvoices);
    }
  }, [invoiceID, invoicesHook.invoices, clientID, jobID, setFilteredInvoices]);

  const contextValue = React.useMemo(
    () => ({ ...invoicesHook, selectedInvoice, setSelectedInvoice, filteredInvoices, setFilteredInvoices }),
    [invoicesHook, selectedInvoice, setSelectedInvoice, filteredInvoices, setFilteredInvoices]
  );

  return <InvoicesContext.Provider value={contextValue}>{children}</InvoicesContext.Provider>;
}
