import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { paths } from '@/paths';

import { logError } from '@/utils/log-error';

import { ErrorFallback } from '@/components/core/error-fallback';
import { PublicRoute } from '@/components/core/public-route';

export const route: RouteObject = {
  element: <Outlet />,
  children: [
    {
      path: 'join',
      index: true,
      lazy: async () => {
        const { InviteAcceptancePage } = await import('@/pages/user-onboarding/invite-acceptance');
        return {
          Component: () => {
            const navigate = useNavigate();
            const [searchParams] = useSearchParams();
            const token = searchParams.get('token');

            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <PublicRoute requireToken token={token}>
                  <InviteAcceptancePage />
                </PublicRoute>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: '/join/signup',
      lazy: async () => {
        const { JoinSignupPage } = await import('@/pages/user-onboarding/signup');
        return {
          Component: () => {
            const navigate = useNavigate();
            const [searchParams] = useSearchParams();
            const token = searchParams.get('token');

            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <PublicRoute requireToken token={token}>
                  <JoinSignupPage />
                </PublicRoute>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: '/signup-success',
      lazy: async () => {
        const { SignupSuccessPage } = await import('@/pages/user-onboarding/signup-success');
        return {
          Component: () => (
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
              <SignupSuccessPage />
            </ErrorBoundary>
          ),
        };
      },
    },
  ],
};
