import * as React from 'react';
import {
  useArchivePricebook,
  useCreatePricebook,
  useDeletePricebook,
  useGetPricebooks,
  useRestorePricebook,
  useUpdatePricebook,
} from '@sity-ai/api';
import type { NewPricebook, Pricebook } from '@sity-ai/types';
import { useQueryClient } from '@tanstack/react-query';

import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

export interface PricebooksResponse {
  pricebooks: Pricebook[];
  loading: boolean;
  error: Error | null;
  refreshPricebooks: () => void;
  updatePricebook: (pricebook: Pricebook) => Promise<Pricebook>;
  deletePricebook: (pricebookID: number) => Promise<boolean>;
  createPricebook: (pricebook: NewPricebook) => Promise<Pricebook>;
  archivePricebook: (pricebookID: number, transferToID?: number, itemIDs?: number[]) => Promise<String>;
  restorePricebook: (pricebookID: number) => Promise<Pricebook>;
  getPricebook: (id: number) => Pricebook | undefined;
}

export function usePricebooks(): PricebooksResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  // Fetch pricebooks
  const {
    data: pricebooks = [],
    isLoading: pricebooksLoading,
    error: pricebooksError,
  } = useGetPricebooks({ companyID }, token, {
    enabled: !!companyID && !!token,
    queryKey: [companyID, token],
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  // Imperatively refresh pricebook list
  const refreshPricebookList = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getPricebooks'] });
  };

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: handleUpdatePricebook,
    isPending: isUpdatingPricebook,
    isError: isUpdatePricebookError,
  } = useUpdatePricebook({
    onSuccess: refreshPricebookList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleDeletePricebook,
    isPending: isDeletingPricebook,
    isError: isDeletePricebookError,
  } = useDeletePricebook({
    onSuccess: refreshPricebookList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleCreatePricebook,
    isPending: isCreatingPricebook,
    isError: isCreatePricebookError,
  } = useCreatePricebook({
    onSuccess: refreshPricebookList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleArchivePricebook,
    isPending: isArchivingPricebook,
    isError: isArchivePricebookError,
  } = useArchivePricebook({
    onSuccess: refreshPricebookList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleRestorePricebook,
    isPending: isRestoringPricebook,
    isError: isRestorePricebookError,
  } = useRestorePricebook({
    onSuccess: refreshPricebookList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/
  const updatePricebook = React.useCallback(
    async (pricebook: Pricebook): Promise<Pricebook> => {
      return await handleUpdatePricebook({ params: pricebook, token });
    },
    [handleUpdatePricebook, token]
  );
  const deletePricebook = React.useCallback(
    async (pricebookID: number): Promise<boolean> => {
      const status = await handleDeletePricebook({ params: { pricebookID }, token });
      return status < 400;
    },
    [handleDeletePricebook, token]
  );
  const createPricebook = React.useCallback(
    async (pricebook: NewPricebook): Promise<Pricebook> => {
      return await handleCreatePricebook({ params: pricebook, token });
    },
    [handleCreatePricebook, token]
  );
  const archivePricebook = React.useCallback(
    async (pricebookID: number, transferToID?: number, itemIDs?: number[]): Promise<String> => {
      return await handleArchivePricebook({
        params: {
          pricebookID: pricebookID.toString(),
          companyID,
          targetPricebookID: transferToID?.toString() ?? undefined,
          items: transferToID ? itemIDs : undefined,
        },
        token,
      });
    },
    [handleArchivePricebook, token]
  );
  const restorePricebook = React.useCallback(
    async (pricebookID: number): Promise<Pricebook> => {
      return await handleRestorePricebook({ params: { pricebookID: pricebookID.toString(), companyID }, token });
    },
    [handleRestorePricebook, token]
  );
  const getPricebook = React.useCallback(
    (id: number): Pricebook | undefined => {
      return pricebooks.find((pricebook: Pricebook) => pricebook.pricebookID === id);
    },
    [pricebooks]
  );

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const loading = React.useMemo(() => {
    return (
      pricebooksLoading ||
      isUpdatingPricebook ||
      isDeletingPricebook ||
      isCreatingPricebook ||
      isArchivingPricebook ||
      isRestoringPricebook
    );
  }, [
    pricebooksLoading,
    isUpdatingPricebook,
    isDeletingPricebook,
    isCreatingPricebook,
    isArchivingPricebook,
    isRestoringPricebook,
  ]);
  const error = React.useMemo(() => {
    return (
      pricebooksError ||
      isUpdatePricebookError ||
      isDeletePricebookError ||
      isCreatePricebookError ||
      isArchivePricebookError ||
      isRestorePricebookError
    );
  }, [
    pricebooksError,
    isUpdatePricebookError,
    isDeletePricebookError,
    isCreatePricebookError,
    isArchivePricebookError,
    isRestorePricebookError,
  ]);

  return {
    pricebooks,
    loading,
    error,
    refreshPricebooks: refreshPricebookList,
    updatePricebook,
    deletePricebook,
    createPricebook,
    archivePricebook,
    restorePricebook,
    getPricebook,
  };
}
