import * as React from 'react';
import {
  useCreateCompanyHours,
  useDeleteCompanyHours,
  useInviteUser,
  useUpdateCompany,
  useUpdateCompanyHours,
  useUpdateUser,
} from '@sity-ai/api';
import { NewCompanyHours } from '@sity-ai/types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import { paths } from '@/paths';

import { logger } from '@/lib/default-logger';
import ROLES from '@/constants/roles.json';

import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { useCompanyContext } from '@/contexts/company-context';
import { useCompanyHours } from '@/hooks/use-company-hours';

import { parseCompanyHoursForForm, parseFormDataForEngine } from '@/utils/company-hours/parsers';

import {
  AdminOnboardingWizardDataInitialValue,
  Invitee,
  type AdminOnboardingWizardData,
} from '@/components/admin-onboarding/types';
import { Loading } from '../core/loading';

export interface AdminOnboardingWizardContextValue {
  loading: boolean;
  wizardData: AdminOnboardingWizardData;
  registerStep: (step: number) => void;
  updateWizardData: (data: Partial<AdminOnboardingWizardData>) => void;
  updateWizardDataAndGoToNextStep: (data: Partial<AdminOnboardingWizardData>) => void;
  updateWizardDataAndGoToPreviousStep: (data: Partial<AdminOnboardingWizardData>) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  submitWizard: (data: AdminOnboardingWizardData) => void;
  inviteUsers: (newInvitees: Invitee[]) => Promise<Boolean>;
}

export interface AdminOnboardingWizardProviderProps {
  children: React.ReactNode;
  initialData?: Partial<AdminOnboardingWizardData>;
}

export const AdminOnboardingWizardContext = React.createContext<AdminOnboardingWizardContextValue>({
  loading: false,
  wizardData: AdminOnboardingWizardDataInitialValue,
  registerStep: () => {},
  updateWizardData: () => {},
  updateWizardDataAndGoToNextStep: () => {},
  updateWizardDataAndGoToPreviousStep: () => {},
  goToNextStep: () => {},
  goToPreviousStep: () => {},
  submitWizard: () => {},
  inviteUsers: () => Promise.resolve(false),
});

export function useWizardContext(): AdminOnboardingWizardContextValue {
  const context = React.useContext(AdminOnboardingWizardContext);
  if (!context) {
    throw new Error('useWizardContext must be used within a AdminOnboardingWizardProvider');
  }
  return context;
}

export function AdminOnboardingWizardProvider({
  children,
  initialData,
}: AdminOnboardingWizardProviderProps): React.JSX.Element {
  const { user, refreshUserData, token, isLoading: userLoading } = useUserContext();
  const { company } = useCompanyContext();
  const { hours: existingHours, loading: hoursLoading } = useCompanyHours();

  const companyID = company?.companyID ?? 0;
  const companyHoursFormatted = React.useMemo(
    () =>
      parseCompanyHoursForForm({
        companyID,
        companyHours: existingHours,
        companyTimeZone: company?.timeZone ?? '',
      }),
    [existingHours, company?.timeZone]
  );

  const inviteUser = useInviteUser();
  const { mutateAsync: updateCompany } = useUpdateCompany();
  const { mutateAsync: updateUser } = useUpdateUser();
  const { mutateAsync: updateCompanyHours } = useUpdateCompanyHours();
  const { mutateAsync: createCompanyHours } = useCreateCompanyHours();
  const { mutateAsync: deleteCompanyHours } = useDeleteCompanyHours();

  const defaultValue = {
    ...AdminOnboardingWizardDataInitialValue,
    ...initialData,
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber: user?.contactNumber ?? '',
    jobTitle: user?.jobTitle ?? '',
    companyName: company?.name ?? '',
    companyAddress: {
      streetAddress: company?.streetAddress ?? '',
      route: company?.route ?? '',
      subpremise: company?.subpremise ?? '',
      city: company?.city ?? '',
      stateID: company?.state ?? '',
      postalCode: company?.postalCode ?? '',
    },
    companyHours: [],
    annualRevenue: company?.companyRevenueID ?? undefined,
    industry: Number(company?.industryID) ?? null,
    teamSize: company?.companySizeID ? Number(company.companySizeID) : undefined,
    accountingSystem: company?.accountingSystem ?? undefined,
  };

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [wizardData, setWizardData] = React.useState<AdminOnboardingWizardData>(defaultValue);

  // Update the current step when the number of steps is set
  React.useEffect(() => {
    if (wizardData.numSteps > 0 && wizardData.currentStep === 0) {
      setWizardData((prev) => ({
        ...prev,
        currentStep: 1,
      }));
    }
  }, [wizardData.numSteps, wizardData.currentStep]);

  // Update default value(s) when async data is loaded
  React.useEffect(() => {
    setWizardData((prev) => ({
      ...prev,
      companyHours: companyHoursFormatted.length > 0 ? companyHoursFormatted : [],
    }));
  }, [hoursLoading]);

  const submitWizard = async (data: AdminOnboardingWizardData): Promise<void> => {
    try {
      setLoading(true);
      const params = { companyID: companyID.toString() };

      /************************************************************************
       * Update the company data
       ************************************************************************/
      const existingCompanyData = company!;
      const newCompanyData = {
        ...existingCompanyData,
        name: data.companyName ?? existingCompanyData.name,
        industryID: data.industry ?? existingCompanyData.industryID,
        industryOther: data.industryOther ?? undefined,
        companySizeID: data.teamSize ?? existingCompanyData.companySizeID,
        companyRevenueID: data.annualRevenue ?? existingCompanyData.companyRevenueID,
        onboarded: true,
        streetAddress: data.companyAddress.streetAddress ?? existingCompanyData.streetAddress,
        route: data.companyAddress.route ?? existingCompanyData.route,
        subpremise: data.companyAddress.subpremise ?? existingCompanyData.subpremise,
        city: data.companyAddress.city ?? existingCompanyData.city,
        state: data.companyAddress.stateID ?? existingCompanyData.state,
        postalCode: data.companyAddress.postalCode ?? existingCompanyData.postalCode,
        referral: data.leadSource ?? existingCompanyData.referral,
        accountingSystem: data.accountingSystem ?? undefined,
        accountingSystemOther: data.accountingSystemOther ?? undefined,
      };
      await updateCompany({ params, company: newCompanyData, token });

      /************************************************************************
       * Update the company hours
       ************************************************************************/
      const newCompanyHours: NewCompanyHours[] = parseFormDataForEngine(
        data.companyHours,
        companyID,
        company?.timeZone ?? ''
      );

      // Sort the company hours into three categories: to delete, to update, and to add
      const companyHours = {
        toDelete: newCompanyHours.filter((hour) => hour.hoursID && hour.hoursID < 0),
        toUpdate: newCompanyHours.filter(({ hoursID }) => hoursID && hoursID > 0),
        toAdd: newCompanyHours
          .filter(({ hoursID }) => hoursID === 0)
          .map((hour) => ({
            ...hour,
            hoursID: undefined,
          })),
      };

      // Do the deletes 1st to prevent race conditions
      await Promise.all(
        companyHours.toDelete.map((hour) =>
          deleteCompanyHours({ params: { companyID, hoursID: hour.hoursID! * -1 }, token })
        )
      );

      // Do the updates and adds
      await Promise.all([
        ...companyHours.toUpdate.map((hour) => updateCompanyHours({ params, hoursRecord: hour, token })),
        ...companyHours.toAdd.map((hour) => createCompanyHours({ params, newCompanyHours: hour, token })),
      ]);

      /************************************************************************
       * Update the user data
       ************************************************************************/
      const existingUserData = user!;
      const newUserData = {
        ...existingUserData,
        firstName: data.firstName ?? existingUserData.firstName,
        lastName: data.lastName ?? existingUserData.lastName,
        jobTitle: data.jobTitle ?? existingUserData.jobTitle,
        contactNumber: data.phoneNumber ?? existingUserData.contactNumber,
      };
      await updateUser({ params, user: newUserData, token });

      // Refresh the user data and navigate to the appropriate page
      refreshUserData();
      if (user?.isAdmin) navigate(paths.quickstart, { replace: true });
      else navigate(paths.home, { replace: true });
    } catch (e) {
      logger.error('Failed to submit wizard:', e);
    } finally {
      setLoading(false);
    }
  };

  const registerStep = (step: number) => {
    setWizardData((prev) => ({
      ...prev,
      numSteps: step > prev.numSteps ? step : prev.numSteps,
    }));
  };

  const updateWizardData = (data: Partial<AdminOnboardingWizardData>) => {
    setWizardData((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const goToNextStep = (): void => {
    if (wizardData.currentStep >= wizardData.numSteps) return;
    updateWizardData({ currentStep: wizardData.currentStep + 1 });
  };

  const goToPreviousStep = (): void => {
    if (wizardData.currentStep === 1) return;
    updateWizardData({ currentStep: wizardData.currentStep - 1 });
  };

  const updateWizardDataAndGoToNextStep = (data: Partial<AdminOnboardingWizardData>) => {
    if (wizardData.currentStep === wizardData.numSteps) {
      const newWizardData = { ...wizardData, ...data };
      updateWizardData(newWizardData);
      submitWizard(newWizardData);
    } else {
      updateWizardData(data);
      goToNextStep();
    }
  };

  const updateWizardDataAndGoToPreviousStep = (data: Partial<AdminOnboardingWizardData>) => {
    if (wizardData.currentStep === 1) return;
    updateWizardData(data);
    goToPreviousStep();
  };

  const inviteUsers = async (newInvitees: Invitee[]): Promise<Boolean> => {
    if (!token) {
      logger.error('No token found');
      return false;
    }

    const adminRole = ROLES.find((role) => role.code === 0);

    try {
      setLoading(true);
      const invitePromises = newInvitees.map((invitee) => {
        const params: Invitee = {
          ...invitee,
          isAdmin: invitee.role === adminRole?.id,
          companyID,
        };
        return inviteUser.mutateAsync({ params, token });
      });

      await Promise.all(invitePromises);

      setWizardData((prev) => ({
        ...prev,
        invitees: newInvitees.map((invitee) => ({
          ...invitee,
          inviteUser: true,
        })),
        invitesSent: true,
      }));

      toast.success('Invites sent successfully!');
      return true;
    } catch (e) {
      setWizardData((prev) => ({
        ...prev,
        invitees: prev.invitees.map((invitee) => ({
          ...invitee,
          inviteUser: false,
        })),
        invitesSent: true,
      }));
      logger.error('Failed to send invites:', e);
      toast.error('Failed to send invites');
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminOnboardingWizardContext.Provider
      value={{
        loading,
        registerStep,
        updateWizardData,
        updateWizardDataAndGoToNextStep,
        updateWizardDataAndGoToPreviousStep,
        goToNextStep,
        goToPreviousStep,
        wizardData,
        submitWizard,
        inviteUsers,
      }}
    >
      {userLoading || hoursLoading ? <Loading /> : children}
    </AdminOnboardingWizardContext.Provider>
  );
}
