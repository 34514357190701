import * as React from 'react';
import type { ServiceRecord } from '@sity-ai/types';

import { useServiceRecords, type ActiveServiceRecordActions } from '@/hooks/use-service-records';

/*****************************************************************************
 * Types
 *****************************************************************************/
export interface ServiceRecordsContextValue {
  serviceRecord: ServiceRecord | null;
  serviceRecords: ServiceRecord[];
  refreshServiceRecordList: () => void;
  setActiveServiceRecord: (jobID: number) => ServiceRecord | null;
  loading: boolean;
  loaded: boolean;
  serviceRecordActions: ActiveServiceRecordActions;
  filteredServiceRecords: ServiceRecord[];
  setFilteredServiceRecords: (serviceRecords: ServiceRecord[]) => void;
}
export interface ServiceRecordsProviderProps {
  children: React.ReactNode;
  clientID?: number;
  jobID?: number;
}

/*****************************************************************************
 * Context
 *****************************************************************************/
export const ServiceRecordsContext = React.createContext<ServiceRecordsContextValue>({
  serviceRecord: null,
  serviceRecords: [],
  refreshServiceRecordList: () => undefined,
  setActiveServiceRecord: () => null,
  loading: false,
  loaded: false,
  serviceRecordActions: {
    closeActiveServiceRecord: () => Promise.resolve(false),
    archiveActiveServiceRecord: () => Promise.resolve(false),
    removeAllVisitsFromActiveServiceRecord: () => Promise.resolve(false),
    completeAllPastVisitsForActiveServiceRecord: () => Promise.resolve(false),
    deleteServiceRecord: () => Promise.resolve(false),
    updateJobDetails: () => Promise.resolve(null),
    updateVisit: () => Promise.resolve(null),
    updateServiceRecord: () => Promise.resolve(null),
  },
  filteredServiceRecords: [],
  setFilteredServiceRecords: () => undefined,
});
export function useServiceRecordsContext(): ServiceRecordsContextValue {
  return React.useContext(ServiceRecordsContext);
}

/*****************************************************************************
 * Provider
 *****************************************************************************/
export function ServiceRecordsProvider({ children, clientID, jobID }: ServiceRecordsProviderProps): React.JSX.Element {
  const serviceRecordsHook = useServiceRecords();

  const [filteredServiceRecords, setFilteredServiceRecords] = React.useState<ServiceRecord[]>([]);

  React.useEffect(() => {
    if (clientID && serviceRecordsHook.serviceRecords.length > 0) {
      let filteredServiceRecords = serviceRecordsHook.serviceRecords;
      if (clientID) filteredServiceRecords = filteredServiceRecords.filter((record) => record.clientID === clientID);
      setFilteredServiceRecords(filteredServiceRecords);
    }
  }, [clientID, serviceRecordsHook.serviceRecords, setFilteredServiceRecords]);

  // Set the initial active service record (if specified)
  React.useEffect(() => {
    if (
      jobID && // A job ID was specified
      serviceRecordsHook.serviceRecords.length > 0 && // There are service records
      serviceRecordsHook.serviceRecord === null // The active service record is not yet set
    ) {
      const activeServiceRecord = serviceRecordsHook.serviceRecords.find((record) => record.jobID === jobID);
      if (activeServiceRecord) serviceRecordsHook.setActiveServiceRecord(activeServiceRecord.jobID);
    }
  }, [jobID, serviceRecordsHook.serviceRecords, serviceRecordsHook.setActiveServiceRecord]);

  const contextValue = React.useMemo(
    () => ({ ...serviceRecordsHook, filteredServiceRecords, setFilteredServiceRecords }),
    [serviceRecordsHook, filteredServiceRecords, setFilteredServiceRecords]
  );

  return <ServiceRecordsContext.Provider value={contextValue}>{children}</ServiceRecordsContext.Provider>;
}
