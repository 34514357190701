import * as React from 'react';
import type { Pricebook } from '@sity-ai/types';

import { usePricebooks, type PricebooksResponse } from '@/hooks/use-pricebooks';

/*****************************************************************************
 * Types
 *****************************************************************************/
export interface PricebookContextValue extends PricebooksResponse {
  selectedPricebook: Pricebook | null;
  selectPricebook: (pricebookID: number) => void;
  clearSelectedPricebook: () => void;
}
interface ProviderProps {
  children: React.ReactNode;
}

/*****************************************************************************
 * Context
 *****************************************************************************/
export const PricebookContext = React.createContext<PricebookContextValue>({
  pricebooks: [],
  loading: false,
  error: null,
  refreshPricebooks: () => undefined,
  updatePricebook: () => Promise.resolve({} as Pricebook),
  deletePricebook: () => Promise.resolve(false),
  createPricebook: () => Promise.resolve({} as Pricebook),
  archivePricebook: () => Promise.resolve(''),
  restorePricebook: () => Promise.resolve({} as Pricebook),
  getPricebook: () => undefined,
  selectedPricebook: null,
  selectPricebook: () => undefined,
  clearSelectedPricebook: () => undefined,
});
export function usePricebookContext(): PricebookContextValue {
  return React.useContext(PricebookContext);
}

/*****************************************************************************
 * Provider
 *****************************************************************************/
export function PricebookProvider({ children }: ProviderProps): React.JSX.Element {
  const pricebooksHook = usePricebooks();

  const [selectedPricebook, setSelectedPricebook] = React.useState<Pricebook | null>(null);
  const selectPricebook = (pricebookID: number): void => {
    const pricebook = pricebooksHook.getPricebook(pricebookID);
    if (pricebook) setSelectedPricebook(pricebook);
  };
  const clearSelectedPricebook = (): void => {
    setSelectedPricebook(null);
  };

  const value = React.useMemo(
    () => ({
      ...pricebooksHook,
      selectedPricebook,
      selectPricebook,
      clearSelectedPricebook,
    }),
    [pricebooksHook, selectedPricebook, selectPricebook, clearSelectedPricebook]
  );

  return <PricebookContext.Provider value={value}>{children}</PricebookContext.Provider>;
}
