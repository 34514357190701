import * as React from 'react';
import type { TaxRate } from '@sity-ai/types';

import { useTaxRates, type TaxRatesResponse } from '@/hooks/use-tax-rates';

import { Loading } from '@/components/core/loading';

/*****************************************************************************
 * Types
 *****************************************************************************/
export interface TaxRatesContextValue extends TaxRatesResponse {
  selectedTaxRate: TaxRate | null;
  selectTaxRate: (taxRate: TaxRate) => void;
  clearSelectedTaxRate: () => void;
}
export interface TaxRatesProviderProps {
  children: React.ReactNode;
}

/*****************************************************************************
 * Context
 *****************************************************************************/
export const TaxRatesContext = React.createContext<TaxRatesContextValue>({
  taxRates: [],
  loading: false,
  error: null,
  refreshtaxRatesList: () => undefined,
  createTaxRate: () => Promise.resolve({} as TaxRate),
  updateTaxRate: () => Promise.resolve({} as TaxRate),
  deleteTaxRate: () => Promise.resolve(false),
  selectedTaxRate: null,
  selectTaxRate: () => undefined,
  clearSelectedTaxRate: () => undefined,
});
export function useTaxRatesContext(): TaxRatesContextValue {
  return React.useContext(TaxRatesContext);
}

/*****************************************************************************
 * Provider
 *****************************************************************************/
export function TaxRatesProvider({ children }: TaxRatesProviderProps): React.JSX.Element {
  const taxRatesHook = useTaxRates();

  const [selectedTaxRate, setSelectedTaxRate] = React.useState<TaxRate | null>(null);
  const selectTaxRate = (taxRate: TaxRate): void => {
    setSelectedTaxRate(taxRate);
  };
  const clearSelectedTaxRate = (): void => {
    setSelectedTaxRate(null);
  };

  const contextValue = React.useMemo(
    () => ({ ...taxRatesHook, selectedTaxRate, selectTaxRate, clearSelectedTaxRate }),
    [taxRatesHook, selectedTaxRate, selectTaxRate, clearSelectedTaxRate]
  );

  return (
    <TaxRatesContext.Provider value={contextValue}>
      {taxRatesHook.loading ? <Loading /> : children}
    </TaxRatesContext.Provider>
  );
}
