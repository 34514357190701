import * as React from 'react';
import { useCreateJobLineItem, useDeleteJobLineItem, useGetJobLineItems, useUpdateJobLineItem } from '@sity-ai/api';
import type { JobLineItem, NewJobLineItem } from '@sity-ai/types';
import { useQueryClient } from '@tanstack/react-query';

import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

export interface JobLineItemsResponse {
  jobLineItems: JobLineItem[];
  loading: boolean;
  error: Error | null;
  refreshJobLineItemList: () => void;
  deleteJobLineItem: (jobItemID: number) => Promise<boolean>;
  updateJobLineItem: (jobLineItem: JobLineItem) => Promise<JobLineItem>;
  createJobLineItem: (jobLineItem: NewJobLineItem) => Promise<JobLineItem>;
}

export function useJobLineItems(jobID?: number): JobLineItemsResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  // Fetch all job line items
  const params: { companyID: string; jobID?: string } = { companyID };
  if (jobID) params.jobID = jobID.toString();
  const {
    data: jobLineItems = [],
    isLoading: jobLineItemsLoading,
    error: jobLineItemsError,
  } = useGetJobLineItems(params, token, {
    enabled: !!companyID && !!token,
    queryKey: [companyID, token],
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  // Imperatively refresh job line item list
  const refreshJobLineItemList = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getJobLineItems'] });
  };

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: handleDeleteJobLineItem,
    isLoading: deleteJobLineItemLoading,
    error: deleteJobLineItemError,
  } = useDeleteJobLineItem({
    onSuccess: refreshJobLineItemList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleUpdateJobLineItem,
    isLoading: updateJobLineItemLoading,
    error: updateJobLineItemError,
  } = useUpdateJobLineItem({
    onSuccess: refreshJobLineItemList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleCreateJobLineItem,
    isLoading: createJobLineItemLoading,
    error: createJobLineItemError,
  } = useCreateJobLineItem({
    onSuccess: refreshJobLineItemList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/
  const deleteJobLineItem = React.useCallback(
    async (jobItemID: number): Promise<boolean> => {
      const status = await handleDeleteJobLineItem({
        params: { jobItemID: jobItemID.toString(), companyID },
        token,
      });
      return status < 400;
    },
    [handleDeleteJobLineItem, token]
  );
  const updateJobLineItem = React.useCallback(
    async (jobLineItem: JobLineItem): Promise<JobLineItem> => {
      return await handleUpdateJobLineItem({
        params: { jobLineItemID: jobLineItem.jobItemID.toString(), companyID },
        jobLineItem,
        token,
      });
    },
    [handleUpdateJobLineItem, token]
  );
  const createJobLineItem = React.useCallback(
    async (jobLineItem: NewJobLineItem): Promise<JobLineItem> => {
      return await handleCreateJobLineItem({
        params: { companyID },
        jobLineItem,
        token,
      });
    },
    [handleCreateJobLineItem, token]
  );

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const loading = React.useMemo(() => {
    return jobLineItemsLoading || deleteJobLineItemLoading || updateJobLineItemLoading || createJobLineItemLoading;
  }, [jobLineItemsLoading, deleteJobLineItemLoading, updateJobLineItemLoading, createJobLineItemLoading]);
  const error = React.useMemo(() => {
    return jobLineItemsError || deleteJobLineItemError || updateJobLineItemError || createJobLineItemError;
  }, [jobLineItemsError, deleteJobLineItemError, updateJobLineItemError, createJobLineItemError]);

  return {
    jobLineItems,
    loading,
    error,
    refreshJobLineItemList,
    deleteJobLineItem,
    updateJobLineItem,
    createJobLineItem,
  };
}
