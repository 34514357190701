import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { paths } from '@/paths';

import { PricebookProvider } from '@/contexts/pricebook-context';
import { PricebookItemsProvider } from '@/contexts/pricebook-items-context';

import { logError } from '@/utils/log-error';

import { ErrorFallback } from '@/components/core/error-fallback';
import { UnifiedLayout } from '@/components/hub/layout/nav';
import { ProtectedPage } from '@/components/hub/layout/protected-page';

function DashboardLayout({ children }: { children: React.ReactNode }): React.ReactElement {
  return (
    <PricebookProvider>
      <PricebookItemsProvider>
        <UnifiedLayout>{children}</UnifiedLayout>
      </PricebookItemsProvider>
    </PricebookProvider>
  );
}

export const route: RouteObject = {
  element: (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ),
  children: [
    {
      path: 'pricebooks',
      index: true,
      lazy: async () => {
        const { Page } = await import('@/pages/pricebooks/pricebook-list');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2]}>
                  <Page />
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: 'pricebook/:pricebookId',
      lazy: async () => {
        const { Page } = await import('@/pages/pricebook-items/item-list');
        return {
          Component: () => {
            const navigate = useNavigate();
            const { pricebookId } = useParams();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2]}>
                  <PricebookItemsProvider pricebookId={Number(pricebookId)}>
                    <Page />
                  </PricebookItemsProvider>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: 'pricebook/create',
      lazy: async () => {
        const { Page } = await import('@/pages/pricebooks/pricebook-create');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1]}>
                  <Page />
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: 'pricebook/:pricebookId/item/create',
      lazy: async () => {
        const { Page } = await import('@/pages/pricebook-items/item-create');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1]}>
                  <Page />
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: 'pricebook/:pricebookId/item/:itemId',
      lazy: async () => {
        const { Page } = await import('@/pages/pricebook-items/item-details');
        return {
          Component: () => {
            const navigate = useNavigate();
            const { pricebookId } = useParams();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2]}>
                  <PricebookItemsProvider pricebookId={Number(pricebookId)}>
                    <Page />
                  </PricebookItemsProvider>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
  ],
};
