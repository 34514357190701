import * as React from 'react';
import {
  useCreatePayment,
  useDeletePayment,
  useGetPayments,
  useGetPaymentsByClientId,
  useUpdatePayment,
} from '@sity-ai/api';
import type { NewPayment, Payment } from '@sity-ai/types';
import { useQueryClient } from '@tanstack/react-query';

import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

export interface PaymentsResponse {
  payments: Payment[];
  loading: boolean;
  error: Error | null;
  refreshPaymentsList: () => void;
  createNewPayment: (paymentData: NewPayment) => Promise<Payment>;
  updateExistingPayment: (paymentID: string, paymentData: Payment) => Promise<Payment>;
  deleteExistingPayment: (paymentID: string) => Promise<boolean>;
}

export function usePayments(cid?: number): PaymentsResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() ?? '';
  const clientID = cid?.toString() ?? '';

  // Fetch payments
  const {
    data: payments,
    isLoading: loadingByCompanyId,
    error: errorByCompanyId,
  } = useGetPayments({ companyID }, token, {
    queryKey: [companyID, token],
    enabled: !!companyID && !!token && !clientID,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  // Fetch payments by client ID
  const {
    data: paymentsByClientId,
    isLoading: loadingByClientId,
    error: errorByClientId,
  } = useGetPaymentsByClientId({ companyID, clientID }, token, {
    queryKey: [companyID, clientID, token],
    enabled: !!companyID && !!token && !!clientID,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  // Imperatively refresh payment list
  const refreshPaymentsList = React.useCallback((): void => {
    queryClient.invalidateQueries({ queryKey: ['getPayments'] });
    queryClient.invalidateQueries({ queryKey: ['getPaymentsByClientId'] });
  }, []);

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: handleCreateNewPayment,
    isLoading: createNewPaymentLoading,
    error: createNewPaymentError,
  } = useCreatePayment({
    onSuccess: refreshPaymentsList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleUpdateExistingPayment,
    isLoading: updateExistingPaymentLoading,
    error: updateExistingPaymentError,
  } = useUpdatePayment({
    onSuccess: refreshPaymentsList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleDeleteExistingPayment,
    isLoading: deleteExistingPaymentLoading,
    error: deleteExistingPaymentError,
  } = useDeletePayment({
    onSuccess: refreshPaymentsList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/
  const createNewPayment = React.useCallback(
    async (paymentData: NewPayment): Promise<Payment> => {
      return await handleCreateNewPayment({ params: paymentData, token });
    },
    [handleCreateNewPayment, token]
  );

  const updateExistingPayment = React.useCallback(
    async (paymentID: string, paymentData: Payment): Promise<Payment> => {
      return await handleUpdateExistingPayment({ params: { paymentID }, taxRate: paymentData, token });
    },
    [handleUpdateExistingPayment, token]
  );

  const deleteExistingPayment = React.useCallback(
    async (paymentID: string): Promise<boolean> => {
      const status = await handleDeleteExistingPayment({ params: { companyID, paymentID }, token });
      return status < 400;
    },
    [handleDeleteExistingPayment, companyID, token]
  );

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const loading = React.useMemo(() => {
    return (
      loadingByCompanyId ||
      loadingByClientId ||
      createNewPaymentLoading ||
      updateExistingPaymentLoading ||
      deleteExistingPaymentLoading
    );
  }, [
    loadingByCompanyId,
    loadingByClientId,
    createNewPaymentLoading,
    updateExistingPaymentLoading,
    deleteExistingPaymentLoading,
  ]);

  const error = React.useMemo(() => {
    return (
      errorByCompanyId ||
      errorByClientId ||
      createNewPaymentError ||
      updateExistingPaymentError ||
      deleteExistingPaymentError
    );
  }, [
    errorByCompanyId,
    errorByClientId,
    createNewPaymentError,
    updateExistingPaymentError,
    deleteExistingPaymentError,
  ]);

  return {
    payments: companyID ? (!clientID ? payments : paymentsByClientId) : [],
    loading,
    error,
    refreshPaymentsList,
    createNewPayment,
    updateExistingPayment,
    deleteExistingPayment,
  };
}
