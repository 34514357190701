import * as React from 'react';
import type { NewTimeEntry, TimeEntry } from '@sity-ai/types';

import { useTimeEntries, type TimeEntriesResponse } from '@/hooks/use-time-entries';

/*****************************************************************************
 * Types
 *****************************************************************************/
export interface TimeEntriesContextValue extends TimeEntriesResponse {
  selectedTimeEntry: TimeEntry | null;
  selectTimeEntry: (timeEntry: TimeEntry) => void;
  clearSelectedTimeEntry: () => void;
}
export interface TimeEntriesProviderProps {
  children: React.ReactNode;
  jobID: number;
}

/*****************************************************************************
 * Context
 *****************************************************************************/
export const TimeEntriesContext = React.createContext<TimeEntriesContextValue>({
  timeEntries: [],
  loading: false,
  error: null,
  refreshTimeEntryList: () => undefined,
  deleteTimeEntry: () => Promise.resolve(false),
  updateTimeEntry: () => Promise.resolve({} as TimeEntry),
  selectedTimeEntry: null,
  selectTimeEntry: () => undefined,
  clearSelectedTimeEntry: () => undefined,
  createTimeEntry: () => Promise.resolve({} as TimeEntry),
});
export function useTimeEntriesContext(): TimeEntriesContextValue {
  return React.useContext(TimeEntriesContext);
}

/*****************************************************************************
 * Provider
 *****************************************************************************/
export function TimeEntriesProvider({ children, jobID }: TimeEntriesProviderProps): React.JSX.Element {
  const timeEntries = useTimeEntries({ jobID });

  const [selectedTimeEntry, setSelectedTimeEntry] = React.useState<TimeEntry | null>(null);
  const selectTimeEntry = (timeEntry: TimeEntry): void => {
    setSelectedTimeEntry(timeEntry);
  };
  const clearSelectedTimeEntry = (): void => {
    setSelectedTimeEntry(null);
  };

  const contextValue = React.useMemo(
    () => ({
      ...timeEntries,
      selectedTimeEntry,
      selectTimeEntry,
      clearSelectedTimeEntry,
    }),
    [timeEntries, selectedTimeEntry, selectTimeEntry, clearSelectedTimeEntry]
  );

  return <TimeEntriesContext.Provider value={contextValue}>{children}</TimeEntriesContext.Provider>;
}
