import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDeleteUser, useGetUserByEmail, useUpdateUser } from '@sity-ai/api';
import type { User } from '@sity-ai/types';
import { useQueryClient } from '@tanstack/react-query';

import { logger } from '@/lib/default-logger';
import { Roles } from '@/constants/roles';

import { parseJwt } from '@/utils/parse-jtw';

interface CurrentUserResponse {
  user: User | null;
  loading: boolean;
  error: Error | null;
  refreshUser: () => void;
  updateUser: (user: User) => Promise<User>;
  deleteUser: (user: User) => Promise<boolean>;
  roles: number[];
  token: string;
  userTimeZone: string;
}

export function useCurrentUser(): CurrentUserResponse {
  const [token, setToken] = React.useState<string>('');
  const [loadingToken, setLoadingToken] = React.useState(false);
  const [roles, setRoles] = React.useState<number[]>([]);

  const queryClient = useQueryClient();

  const { user: auth0User, error: auth0UserError, isLoading: auth0UserLoading, getAccessTokenSilently } = useAuth0();

  // Get the token and roles
  const fetchToken = React.useCallback(async () => {
    try {
      setLoadingToken(true);

      const tokenResponse = await getAccessTokenSilently({ detailedResponse: true });
      setToken(tokenResponse.id_token);

      const tokenObj = parseJwt(tokenResponse.id_token);
      const userRoles: number[] = Array.isArray(tokenObj.userRoles)
        ? tokenObj.userRoles.map((role: string) => {
            const roleNumber = Roles[role];
            if (roleNumber === undefined) {
              logger.error(`Unknown role: ${role}`);
              return -1;
            }
            return roleNumber;
          })
        : [];
      setRoles(userRoles);
    } catch (e) {
      logger.error('Error getting the access token:', e);
      setToken('');
    } finally {
      setLoadingToken(false);
    }
  }, [getAccessTokenSilently]);
  React.useEffect(() => {
    if (auth0User?.email) fetchToken();
  }, [auth0User?.email, fetchToken]);

  const {
    data: user,
    isLoading: userLoading,
    error: userError,
  } = useGetUserByEmail({ action: 'getByEmail', email: auth0User?.email }, token, {
    enabled: !!auth0User?.email && !!token,
    queryKey: ['getUserByEmail', auth0User?.email, token],
  });

  // Get the user time zone
  const userTimeZone = React.useMemo(() => {
    if (user?.timeZone) return user.timeZone;
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }, [user]);

  // Log errors
  React.useEffect(() => {
    if (userError || auth0UserError) logger.error(userError || auth0UserError);
  }, [userError, auth0UserError]);

  // Refresh user
  const refreshUser = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getUserByEmail'] });
  };

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: handleUpdateUser,
    isLoading: updateUserLoading,
    error: updateUserError,
  } = useUpdateUser({
    onSuccess: refreshUser,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleDeleteUser,
    isLoading: deleteUserLoading,
    error: deleteUserError,
  } = useDeleteUser({
    onSuccess: refreshUser,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/
  const updateUser = React.useCallback(
    async (user: User): Promise<User> => {
      return await handleUpdateUser({
        params: { userID: user.userID },
        user,
        token,
      });
    },
    [handleUpdateUser, token]
  );
  const deleteUser = React.useCallback(async (): Promise<boolean> => {
    const status = await handleDeleteUser({
      params: { userID: user.userID, companyID: user.companyID },
      token,
    });
    return status < 400;
  }, [handleDeleteUser, token, user]);

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const loading = React.useMemo(
    () => userLoading || updateUserLoading || deleteUserLoading || auth0UserLoading || loadingToken,
    [userLoading, updateUserLoading, deleteUserLoading, auth0UserLoading, loadingToken]
  );
  const error = React.useMemo(
    () => userError || updateUserError || deleteUserError || auth0UserError,
    [userError, updateUserError, deleteUserError, auth0UserError]
  );

  return {
    deleteUser,
    error,
    loading,
    refreshUser,
    roles,
    token,
    updateUser,
    user,
    userTimeZone,
  };
}
