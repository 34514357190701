import * as React from 'react';
import type { Client, NewClient, NewProperty, Property } from '@sity-ai/types';

import { useClients, type ClientsResponse } from '@/hooks/use-clients';

/*****************************************************************************
 * Types
 *****************************************************************************/
export interface ClientContextValue extends ClientsResponse {
  selectedClient: Client | null;
  setSelectedClient: (client: Client) => void;
}
export interface ClientProviderProps {
  children: React.ReactNode;
  clientID?: number;
}

/*****************************************************************************
 * Context
 *****************************************************************************/
export const ClientsContext = React.createContext<ClientContextValue>({
  clients: [],
  deleteClient: async () => false,
  createClient: async (client: NewClient) => ({ ...client, clientID: 0 }) as Client,
  deleteClients: async () => [],
  refreshClientList: () => undefined,
  updateClient: async (client: Client) => client,
  createPropertyForClient: async (property: NewProperty) => ({ ...property, propertyID: 0 }) as Property,
  loading: false,
  selectedClient: null,
  setSelectedClient: () => undefined,
  error: null,
});
export function useClientsContext(): ClientContextValue {
  return React.useContext(ClientsContext);
}

/*****************************************************************************
 * Provider
 *****************************************************************************/
export function ClientsProvider({ children, clientID }: ClientProviderProps): React.JSX.Element {
  const clientsHook = useClients();

  // Allow the selected client to be set
  const [selectedClient, setSelectedClient] = React.useState<Client | null>(null);

  // Set the selected client ID by the default provided clientID
  React.useEffect(() => {
    if (clientID && clientsHook.clients.length > 0) {
      const client = clientsHook.clients.find((client) => client.clientID === clientID) ?? null;
      setSelectedClient(client);
    }
  }, [clientID, clientsHook.clients, setSelectedClient]);

  const contextValue = React.useMemo(
    () => ({ ...clientsHook, selectedClient, setSelectedClient }),
    [clientsHook, selectedClient, setSelectedClient]
  );

  return <ClientsContext.Provider value={contextValue}>{children}</ClientsContext.Provider>;
}
