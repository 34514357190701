import * as React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Info } from '@phosphor-icons/react';

import { SyncDirectionWithLogos } from '@/components/integrations/common/sync/sync-direction-with-logos';
import type { SyncConfigurationItem, SyncSectionProps } from '@/components/integrations/common/sync/types';

export function SyncSection({
  title,
  icon,
  items,
  onItemChange,
  sourceIcon,
  targetIcon,
}: SyncSectionProps): React.JSX.Element {
  const renderSyncItem = (item: SyncConfigurationItem): React.JSX.Element => {
    return (
      <Box
        key={item.id}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          p: 2,
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          mb: 1,
          backgroundColor: 'background.paper',
          transition: 'all 0.2s',
          '&:hover': {
            borderColor: 'primary.main',
            backgroundColor: 'background.default',
          },
        }}
      >
        <Box sx={{ flex: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={item.enabled}
                onChange={(e) => {
                  onItemChange(item.id, e.target.checked);
                }}
              />
            }
            label={
              <Stack spacing={0.5}>
                <Typography variant="body1">{item.label}</Typography>
                <Typography color="text.secondary" variant="body2">
                  {item.description}
                </Typography>
                {item.status ? (
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <Chip
                      color={item.status === 'active' ? 'success' : item.status === 'error' ? 'error' : 'warning'}
                      label={item.status}
                      size="small"
                    />
                    {item.lastSynced ? (
                      <Typography color="text.secondary" variant="caption">
                        Last synced: {new Date(item.lastSynced).toLocaleString()}
                      </Typography>
                    ) : null}
                  </Stack>
                ) : null}
              </Stack>
            }
            sx={{
              alignItems: 'flex-start',
              margin: 0,
              '& .MuiCheckbox-root': {
                paddingTop: 0,
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mt: { xs: 2, md: 0 },
            pt: { xs: 2, md: 0 },
            borderTop: { xs: '1px solid', md: 'none' },
            borderColor: 'rgba(0, 0, 0, 0.12) !important',
            justifyContent: { xs: 'center', md: 'flex-end' },
            minWidth: { md: '200px' },
          }}
        >
          <Tooltip title={`${item.direction.type === 'one-way' ? 'One-way' : 'Two-way'} sync`}>
            <Box>
              <SyncDirectionWithLogos
                direction={item.direction}
                size={28}
                sourceIcon={item.direction.from === 'provider' ? targetIcon : sourceIcon}
                targetIcon={item.direction.to === 'provider' ? targetIcon : sourceIcon}
              />
            </Box>
          </Tooltip>
          <Tooltip title="Learn more about this sync option">
            <IconButton size="small">
              <Info size={16} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Stack alignItems="center" direction="row" spacing={1} sx={{ mb: 2 }}>
        {icon}
        <Typography variant="h6">{title}</Typography>
      </Stack>
      <Stack spacing={2}>
        {items.length === 0 ? (
          <Alert severity="info">No sync options available for this section.</Alert>
        ) : (
          items.map(renderSyncItem)
        )}
      </Stack>
    </Box>
  );
}
