import Types from '@sity-ai/types';

import { Roles } from '@/constants/roles';

import { RolesWithPermissions } from './types';

const ROLES = {
  [Roles.FieldSity_Admin]: {
    address: { create: true, read: true, update: true, delete: true },
    billing: { create: true, read: true, update: true, delete: true },
    attachment: { create: true, read: true, update: true, delete: true },
    client: { create: true, read: true, update: true, delete: true },
    companyHours: { create: true, read: true, update: true, delete: true },
    company: { create: true, read: true, update: true, delete: true },
    dayVisit: { create: true, read: true, update: true, delete: true },
    email: { create: true, read: true, update: true, delete: true },
    event: {
      create: true,
      createOwn: true,
      read: true,
      readOwn: true,
      update: true,
      updateOwn: true,
      delete: true,
      deleteOwn: true,
    },
    integration: { create: true, read: true, update: true, delete: true },
    invitee: { create: true, read: true, update: true, delete: true },
    invoice: { create: true, read: true, update: true, delete: true },
    jobAssignment: { create: true, read: true, update: true, delete: true },
    jobLineItem: { create: true, read: true, update: true, delete: true },
    payment: { create: true, collect: true, read: true, update: true, delete: true },
    phone: { create: true, read: true, update: true, delete: true },
    pricebook: { create: true, read: true, update: true, delete: true },
    pricebookItem: { create: true, read: true, update: true, delete: true },
    property: { create: true, read: true, update: true, delete: true },
    role: { create: true, read: true, update: true, delete: true },
    serviceRecord: { create: true, read: true, update: true, delete: true, close: true },
    taxRate: { create: true, read: true, update: true, delete: true },
    timeEntry: { create: true, read: true, update: true, delete: true },
    user: { create: true, read: true, update: true, delete: true },
    visit: {
      create: true,
      createOwn: true,
      read: true,
      readOwn: true,
      update: true,
      updateOwn: true,
      delete: true,
      deleteOwn: true,
    },
  },
  [Roles.FieldSity_Manager]: {
    address: { create: true, read: true, update: true, delete: true },
    attachment: { create: true, read: true, update: true, delete: true },
    billing: { create: false, read: false, update: false, delete: false },
    client: { create: true, read: true, update: true, delete: true },
    companyHours: { create: false, read: true, update: false, delete: false },
    company: { create: true, read: true, update: true, delete: true },
    dayVisit: { create: true, read: true, update: true, delete: true },
    email: { create: true, read: true, update: true, delete: true },
    event: {
      create: true,
      createOwn: true,
      read: true,
      readOwn: true,
      update: true,
      updateOwn: true,
      delete: true,
      deleteOwn: true,
    },
    integration: { create: false, read: true, update: false, delete: false },
    invitee: { create: true, read: true, update: true, delete: true },
    invoice: { create: true, read: true, update: true, delete: true },
    jobLineItem: { create: true, read: true, update: true, delete: true },
    jobAssignment: { create: true, read: true, update: true, delete: true },
    payment: { create: true, collect: true, read: true, update: true, delete: true },
    phone: { create: true, read: true, update: true, delete: true },
    pricebook: { create: true, read: true, update: true, delete: true },
    pricebookItem: { create: true, read: true, update: true, delete: true },
    property: { create: true, read: true, update: true, delete: true },
    role: { create: false, read: true, update: false, delete: false },
    serviceRecord: { create: true, read: true, update: true, delete: true, close: true },
    taxRate: { create: true, read: true, update: true, delete: true },
    timeEntry: { create: true, read: true, update: true, delete: true },
    user: { create: true, read: true, update: true, delete: true },
    visit: {
      create: true,
      createOwn: true,
      read: true,
      readOwn: true,
      update: true,
      updateOwn: true,
      delete: true,
      deleteOwn: true,
    },
  },
  [Roles.FieldSity_Dispatch]: {
    address: { create: true, read: true, update: true, delete: true },
    attachment: { create: true, read: true, update: true, delete: true },
    billing: { create: false, read: false, update: false, delete: false },
    client: { create: true, read: true, update: true, delete: true },
    companyHours: { create: false, read: true, update: false, delete: false },
    company: { create: true, read: true, update: true, delete: true },
    dayVisit: { create: true, read: true, update: true, delete: true },
    email: { create: true, read: true, update: true, delete: true },
    event: {
      create: true,
      createOwn: true,
      read: true,
      readOwn: true,
      update: true,
      updateOwn: true,
      delete: true,
      deleteOwn: true,
    },
    integration: { create: false, read: true, update: false, delete: false },
    invitee: { create: false, read: true, update: true, delete: true },
    invoice: { create: false, read: false, update: false, delete: false },
    jobLineItem: { create: true, read: true, update: true, delete: true },
    jobAssignment: { create: true, read: true, update: true, delete: true },
    payment: { create: true, collect: true, read: true, update: true, delete: true },
    phone: { create: true, read: true, update: true, delete: true },
    pricebook: { create: false, read: true, update: false, delete: false },
    pricebookItem: { create: false, read: true, update: false, delete: false },
    property: { create: true, read: true, update: true, delete: true },
    role: { create: false, read: true, update: false, delete: false },
    serviceRecord: { create: true, read: true, update: true, delete: true, close: true },
    taxRate: { create: true, read: true, update: true, delete: true },
    timeEntry: { create: true, read: true, update: true, delete: true },
    user: { create: false, read: true, update: false, delete: false },
    visit: {
      create: true,
      createOwn: true,
      read: true,
      readOwn: true,
      update: true,
      updateOwn: true,
      delete: true,
      deleteOwn: true,
    },
  },
  [Roles.FieldSity_Tech]: {
    address: { create: true, read: true, update: true, delete: true },
    attachment: { create: true, read: true, update: true, delete: true },
    billing: { create: false, read: false, update: false, delete: false },
    client: { create: false, read: true, update: false, delete: false },
    companyHours: { create: false, read: true, update: false, delete: false },
    company: { create: false, read: true, update: false, delete: false },
    dayVisit: { create: true, read: true, update: true, delete: true },
    email: { create: true, read: true, update: true, delete: true },
    event: {
      create: false,
      createOwn: true,
      read: false,
      readOwn: true,
      update: false,
      updateOwn: true,
      delete: false,
      deleteOwn: true,
    },
    integration: { create: false, read: true, update: false, delete: false },
    invitee: { create: false, read: true, update: true, delete: true },
    invoice: { create: false, read: false, update: false, delete: false },
    jobLineItem: { create: false, read: true, update: false, delete: false },
    jobAssignment: { create: false, read: true, update: false, delete: false },
    payment: { create: true, collect: false, read: true, update: true, delete: true },
    phone: { create: true, read: true, update: true, delete: true },
    pricebook: { create: false, read: true, update: false, delete: false },
    pricebookItem: { create: false, read: true, update: false, delete: false },
    property: { create: false, read: true, update: false, delete: false },
    role: { create: false, read: true, update: false, delete: false },
    serviceRecord: { create: false, read: true, update: false, delete: false, close: false },
    taxRate: { create: true, read: true, update: true, delete: true },
    timeEntry: { create: true, read: true, update: true, delete: true },
    user: { create: false, read: true, update: false, delete: false },
    visit: {
      create: false,
      createOwn: true,
      read: false,
      readOwn: true,
      update: false,
      updateOwn: true,
      delete: false,
      deleteOwn: true,
    },
  },
  [Roles.FieldSity_Limited]: {
    address: { create: false, read: true, update: false, delete: false },
    attachment: { create: false, read: true, update: false, delete: false },
    billing: { create: false, read: false, update: false, delete: false },
    client: { create: false, read: true, update: false, delete: false },
    companyHours: { create: false, read: true, update: false, delete: false },
    company: { create: false, read: true, update: false, delete: false },
    dayVisit: { create: false, read: true, update: false, delete: false },
    email: { create: false, read: true, update: false, delete: false },
    event: {
      create: false,
      createOwn: false,
      read: false,
      readOwn: true,
      update: false,
      updateOwn: false,
      delete: false,
      deleteOwn: false,
    },
    integration: { create: false, read: true, update: false, delete: false },
    invitee: { create: false, read: true, update: false, delete: false },
    invoice: { create: false, read: false, update: false, delete: false },
    jobAssignment: { create: false, read: true, update: false, delete: false },
    jobLineItem: { create: false, read: false, update: false, delete: false },
    payment: { create: false, collect: false, read: true, update: false, delete: false },
    phone: { create: false, read: true, update: false, delete: false },
    pricebook: { create: false, read: true, update: false, delete: false },
    pricebookItem: { create: false, read: true, update: false, delete: false },
    property: { create: false, read: true, update: false, delete: false },
    role: { create: false, read: true, update: false, delete: false },
    serviceRecord: { create: false, read: true, update: false, delete: false, close: false },
    taxRate: { create: false, read: true, update: false, delete: false },
    timeEntry: { create: false, read: true, update: false, delete: false },
    user: { create: false, read: true, update: false, delete: false },
    visit: {
      create: false,
      createOwn: false,
      read: false,
      readOwn: true,
      update: false,
      updateOwn: false,
      delete: false,
      deleteOwn: false,
    },
  },
  [Roles.Sity_DEV]: {
    address: { create: true, read: true, update: true, delete: true },
    attachment: { create: true, read: true, update: true, delete: true },
    billing: { create: false, read: true, update: false, delete: false },
    client: { create: true, read: true, update: true, delete: true },
    companyHours: { create: true, read: true, update: true, delete: true },
    company: { create: true, read: true, update: true, delete: true },
    dayVisit: { create: true, read: true, update: true, delete: true },
    email: { create: true, read: true, update: true, delete: true },
    event: {
      create: true,
      createOwn: true,
      read: true,
      readOwn: true,
      update: true,
      updateOwn: true,
      delete: true,
      deleteOwn: true,
    },
    integration: { create: false, read: true, update: false, delete: false },
    invitee: { create: false, read: true, update: true, delete: true },
    invoice: { create: false, read: true, update: false, delete: false },
    jobLineItem: { create: true, read: true, update: true, delete: true },
    jobAssignment: { create: true, read: true, update: true, delete: true },
    payment: { create: true, collect: true, read: true, update: true, delete: true },
    phone: { create: true, read: true, update: true, delete: true },
    pricebook: { create: true, read: true, update: true, delete: true },
    pricebookItem: { create: true, read: true, update: true, delete: true },
    property: { create: true, read: true, update: true, delete: true },
    role: { create: true, read: true, update: true, delete: true },
    serviceRecord: { create: true, read: true, update: true, delete: true, close: true },
    taxRate: { create: true, read: true, update: true, delete: true },
    timeEntry: { create: true, read: true, update: true, delete: true },
    user: { create: true, read: true, update: true, delete: true },
    visit: {
      create: true,
      createOwn: true,
      read: true,
      readOwn: true,
      update: true,
      updateOwn: true,
      delete: true,
      deleteOwn: true,
    },
  },
  [Roles.Sity_PROD]: {
    address: { create: true, read: true, update: true, delete: true },
    attachment: { create: true, read: true, update: true, delete: true },
    billing: { create: false, read: true, update: false, delete: false },
    client: { create: true, read: true, update: true, delete: true },
    companyHours: { create: true, read: true, update: true, delete: true },
    company: { create: true, read: true, update: true, delete: true },
    dayVisit: { create: true, read: true, update: true, delete: true },
    email: { create: true, read: true, update: true, delete: true },
    event: {
      create: true,
      createOwn: true,
      read: true,
      readOwn: true,
      update: true,
      updateOwn: true,
      delete: true,
      deleteOwn: true,
    },
    integration: { create: true, read: true, update: true, delete: true },
    invitee: { create: false, read: true, update: true, delete: true },
    invoice: { create: false, read: true, update: false, delete: false },
    jobLineItem: { create: true, read: true, update: true, delete: true },
    jobAssignment: { create: true, read: true, update: true, delete: true },
    payment: { create: true, collect: true, read: true, update: true, delete: true },
    phone: { create: true, read: true, update: true, delete: true },
    pricebook: { create: true, read: true, update: true, delete: true },
    pricebookItem: { create: true, read: true, update: true, delete: true },
    property: { create: true, read: true, update: true, delete: true },
    role: { create: true, read: true, update: true, delete: true },
    serviceRecord: { create: true, read: true, update: true, delete: true, close: true },
    taxRate: { create: true, read: true, update: true, delete: true },
    timeEntry: { create: true, read: true, update: true, delete: true },
    user: { create: true, read: true, update: true, delete: true },
    visit: {
      create: true,
      createOwn: true,
      read: true,
      readOwn: true,
      update: true,
      updateOwn: true,
      delete: true,
      deleteOwn: true,
    },
  },
} as const satisfies RolesWithPermissions;

export default ROLES;
