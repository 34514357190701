import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { paths } from '@/paths';

import { CompanyProvider } from '@/contexts/company-context';

import { logError } from '@/utils/log-error';

import { ErrorFallback } from '@/components/core/error-fallback';
import { ProtectedPage } from '@/components/hub/layout/protected-page';

function PDFLayout({ children }: { children: React.ReactNode }): React.ReactElement {
  return <CompanyProvider>{children}</CompanyProvider>;
}

export const route: RouteObject = {
  element: (
    <PDFLayout>
      <Outlet />
    </PDFLayout>
  ),
  children: [
    {
      path: 'pdf/invoices/:invoiceID',
      children: [
        {
          index: true,
          lazy: async () => {
            const { Page } = await import('@/pages/pdf/invoice');

            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.invoices.list);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1, 2, 3, 5, 6]}>
                      <React.Suspense fallback={<div>Loading PDF viewer...</div>}>
                        <Page />
                      </React.Suspense>
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
      ],
    },
  ],
};
