import * as React from 'react';
import {
  useCopyPricebook as useCopyPricebookItem,
  useCreatePricebookItem,
  useDeletePricebookItem,
  useGetPricebookItems,
  useMovePricebookItems,
  usePricebookItemStatus,
  useUpdatePricebookItem,
} from '@sity-ai/api';
import type { NewPricebookItem, PricebookItem } from '@sity-ai/types';

import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

export interface PricebookItemsResponse {
  pricebookItems: PricebookItem[];
  loading: boolean;
  error: Error | null;
  refreshPricebookItems: () => void;
  moveItems: (selectedItemIds: number[], targetPricebookId: number) => Promise<boolean>;
  copyItem: (itemId: number) => Promise<PricebookItem>;
  updateItemsStatus: (pricebookId: number, isActive: boolean) => Promise<boolean>;
  updateItem: (pricebookItem: PricebookItem) => Promise<PricebookItem>;
  createItem: (pricebookItem: NewPricebookItem) => Promise<PricebookItem>;
  deleteItem: (pricebookItemId: number) => Promise<boolean>;
}

export function usePricebookItems(): PricebookItemsResponse {
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  // Fetch pricebook items
  const {
    data: pricebookItems = [],
    isLoading: pricebookItemsLoading,
    error: pricebookItemsError,
    refetch,
  } = useGetPricebookItems({ companyID }, token, {
    enabled: !!companyID && !!token,
    queryKey: [companyID, token],
  });

  // Log errors
  React.useEffect(() => {
    if (pricebookItemsError) logger.error(pricebookItemsError);
  }, [pricebookItemsError]);

  const refreshPricebookItems = React.useCallback(() => {
    refetch({ stale: true });
  }, [refetch]);

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: movePricebookItems,
    isPending: isMovingItems,
    isError: isMoveError,
  } = useMovePricebookItems({
    onSuccess: refreshPricebookItems,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: copyPricebookItem,
    isPending: isCopyingItem,
    isError: isCopyError,
  } = useCopyPricebookItem({
    onSuccess: refreshPricebookItems,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: updatePricebookItemsStatus,
    isPending: isUpdatingItemsStatus,
    isError: isUpdatePricebookItemStatusError,
  } = usePricebookItemStatus({
    onSuccess: refreshPricebookItems,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: updatePricebookItem,
    isPending: isUpdatingItem,
    isError: isUpdatePricebookItemError,
  } = useUpdatePricebookItem({
    onSuccess: refreshPricebookItems,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: createPricebookItem,
    isPending: isCreatingItem,
    isError: isCreateError,
  } = useCreatePricebookItem({
    onSuccess: refreshPricebookItems,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: deletePricebookItem,
    isPending: isDeletingItem,
    isError: isDeleteError,
  } = useDeletePricebookItem({
    onSuccess: refreshPricebookItems,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/
  const moveItems = React.useCallback(
    async (selectedItemIds: number[], targetPricebookId: number): Promise<boolean> => {
      return await movePricebookItems({
        params: { pricebookItemIDs: selectedItemIds, pricebookID: targetPricebookId },
        token,
      });
    },
    [movePricebookItems, token]
  );
  const copyItem = React.useCallback(
    async (itemId: number): Promise<PricebookItem> => {
      return await copyPricebookItem({ params: { pricebookItemID: itemId }, token });
    },
    [copyPricebookItem, token]
  );
  const updateItemsStatus = React.useCallback(
    async (pricebookId: number, isActive: boolean): Promise<boolean> => {
      return await updatePricebookItemsStatus({ params: { pricebookID: pricebookId, isActive }, token });
    },
    [updatePricebookItemsStatus, token]
  );
  const updateItem = React.useCallback(
    async (pricebookItem: PricebookItem): Promise<PricebookItem> => {
      return await updatePricebookItem({ params: pricebookItem, pricebookItem, token });
    },
    [updatePricebookItem, token]
  );
  const createItem = React.useCallback(
    async (pricebookItem: NewPricebookItem): Promise<PricebookItem> => {
      return await createPricebookItem({ params: pricebookItem, token });
    },
    [createPricebookItem, token]
  );
  const deleteItem = React.useCallback(
    async (pricebookItemId: number): Promise<boolean> => {
      const status = await deletePricebookItem({ params: { pricebookItemID: pricebookItemId }, token });
      return status < 400;
    },
    [deletePricebookItem, token]
  );

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const loading = React.useMemo(() => {
    return (
      isMovingItems ||
      isCopyingItem ||
      isUpdatingItemsStatus ||
      isUpdatingItem ||
      isCreatingItem ||
      isDeletingItem ||
      pricebookItemsLoading
    );
  }, [
    isMovingItems,
    isCopyingItem,
    isUpdatingItemsStatus,
    isUpdatingItem,
    isCreatingItem,
    isDeletingItem,
    pricebookItemsLoading,
  ]);
  const error = React.useMemo(() => {
    return (
      isMoveError ||
      isCopyError ||
      isUpdatePricebookItemStatusError ||
      isUpdatePricebookItemError ||
      isCreateError ||
      isDeleteError ||
      pricebookItemsError
    );
  }, [
    isMoveError,
    isCopyError,
    isUpdatePricebookItemStatusError,
    isUpdatePricebookItemError,
    isCreateError,
    isDeleteError,
    pricebookItemsError,
  ]);

  return {
    pricebookItems,
    loading,
    error,
    refreshPricebookItems,
    moveItems,
    copyItem,
    updateItemsStatus,
    updateItem,
    createItem,
    deleteItem,
  };
}
