import * as React from 'react';
import { useCreateInvoice, useGetInvoices, useUpdateInvoice } from '@sity-ai/api';
import type { Invoice, InvoiceCreationRequest, InvoiceUpdateRequest } from '@sity-ai/types';
import { useQueryClient } from '@tanstack/react-query';

import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

export interface InvoicesResponse {
  invoices: Invoice[];
  grandTotal: number;
  totalsByJob: Record<string, number>;
  loading: boolean;
  error: Error | null;
  refreshInvoiceList: () => void;
  updateInvoice: (invoice: InvoiceUpdateRequest) => Promise<Invoice>;
  createInvoice: (invoice: InvoiceCreationRequest) => Promise<Invoice>;
}

export function useInvoices(clientID?: string): InvoicesResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString();

  // Fetch invoices
  const {
    data: invoiceResponse = [],
    isLoading: invoicesLoading,
    error: invoicesError,
  } = useGetInvoices({ companyID }, token, {
    queryKey: [companyID, clientID, token],
    enabled: !!companyID && !!token,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  // Memoize invoices
  const invoices = React.useMemo(() => {
    return invoiceResponse?.invoicesWithLineItemsAndClientDetails ?? [];
  }, [invoiceResponse]);

  // Imperatively refresh invoice list
  const refreshInvoiceList = React.useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['getInvoices'] });
  }, []);

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: handleUpdateInvoice,
    isLoading: updateInvoiceLoading,
    error: updateInvoiceError,
  } = useUpdateInvoice({
    onSuccess: refreshInvoiceList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleCreateInvoice,
    isLoading: createInvoiceLoading,
    error: createInvoiceError,
  } = useCreateInvoice({
    onSuccess: refreshInvoiceList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/

  // Calculate grand total
  const grandTotal = React.useMemo(() => {
    if (invoices.length === 0) return 0;
    const filteredInvoices = clientID
      ? invoices.filter((invoice: Invoice) => invoice.clientID?.toString() === clientID)
      : invoices;
    return filteredInvoices.reduce((sum: number, invoice: Invoice) => sum + (invoice.jobTotal ?? 0), 0);
  }, [invoices, clientID]);

  // Update invoice
  const updateInvoice = React.useCallback(
    async (invoice: InvoiceUpdateRequest): Promise<Invoice> => {
      return await handleUpdateInvoice({
        params: { companyID, invoiceID: invoice.invoiceID.toString() },
        token,
      });
    },
    [handleUpdateInvoice, companyID, token]
  );

  // Create invoice
  const createInvoice = React.useCallback(
    async (invoice: InvoiceCreationRequest): Promise<Invoice> => {
      return await handleCreateInvoice({ params: { companyID, invoice }, token });
    },
    [handleCreateInvoice, companyID, token]
  );

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const loading = React.useMemo(() => {
    return invoicesLoading || updateInvoiceLoading || createInvoiceLoading;
  }, [invoicesLoading, updateInvoiceLoading, createInvoiceLoading]);
  const error = React.useMemo(() => {
    return invoicesError || updateInvoiceError || createInvoiceError;
  }, [invoicesError, updateInvoiceError, createInvoiceError]);

  return {
    invoices: invoices || [],
    error,
    loading,
    refreshInvoiceList,
    totalsByJob: invoices?.jobIDTotals ?? {},
    grandTotal,
    updateInvoice,
    createInvoice,
  };
}
