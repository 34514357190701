import Types from '@sity-ai/types';

import { Permissions, RolesWithPermissions } from './types';
import ROLES from './roles';

function checkPermission<Resource extends keyof Permissions>(
    user: Types.User,
    userRoles: number[],
    resource: Resource,
    action: Permissions[Resource]["action"],
    data?: Permissions[Resource]["dataType"]
): boolean {
    return userRoles.some((role) => {
        const permission = (ROLES as RolesWithPermissions)[role][resource]?.[action];
        if (permission == null) return false;

        if (typeof permission === "boolean") return permission;
        return data != null && permission(user, data);
    });    
}

export default checkPermission;