import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import useTimeout from '@/hooks/use-timeout';

export function Loading(): React.JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showBailoutMsg, setShowBailoutMessage] = React.useState<boolean>(false);

  useTimeout(() => {
    setShowBailoutMessage(true);
  }, 30000);

  return (
    <Backdrop
      open
      sx={(theme) => ({
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
      })}
    >
      {showBailoutMsg ? null : <CircularProgress />}
      {showBailoutMsg ? (
        <Stack alignItems="center" spacing={2}>
          <CircularProgress />
          <Typography>Hmm, this is taking awhile...</Typography>
          <Button
            color="primary"
            onClick={() => {
              navigate(-1);
            }}
            variant="contained"
          >
            Go Back
          </Button>
        </Stack>
      ) : null}
    </Backdrop>
  );
}
