import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { paths } from '@/paths';

import { useCompanyContext } from '@/contexts/company-context';

function TrialStatus(): React.JSX.Element | null {
  const navigate = useNavigate();
  const theme = useTheme();
  const { subscription } = useCompanyContext();

  // Safely access the nested subscription object
  const stripeSubscription = subscription?.subscription ?? null;

  // Early return if trial_end or trial_start is not present
  if (!stripeSubscription?.trial_end || !stripeSubscription.trial_start) {
    return null;
  }

  const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds

  // Check if the current time is within the trial period
  const isInTrial =
    currentTimestamp >= stripeSubscription.trial_start && currentTimestamp < stripeSubscription.trial_end;

  if (!isInTrial) {
    return null;
  }

  const secondsInDay = 60 * 60 * 24;
  const daysRemaining = Math.max(0, Math.ceil((stripeSubscription.trial_end - currentTimestamp) / secondsInDay));

  const totalSecondsInPeriod = stripeSubscription.trial_end - stripeSubscription.trial_start;
  const totalDaysInPeriod = Math.ceil(totalSecondsInPeriod / secondsInDay);

  const percentComplete = Math.min(100, ((totalDaysInPeriod - daysRemaining) / totalDaysInPeriod) * 100);

  // Determine color based on percent complete
  let color: string = theme.palette.text.secondary; // Default color
  if (percentComplete > 90 || daysRemaining <= 0) {
    color = theme.palette.error.main; // Red
  } else if (percentComplete > 75) {
    color = theme.palette.warning.main; // Yellow
  } else {
    color = theme.palette.primary.main; // Green
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        alignItems: 'center',
        minWidth: 'max-content',
      }}
    >
      <Typography variant="body2">
        Trial expires in{' '}
        <Typography
          component="span"
          sx={{
            color,
            fontWeight: 'bold',
          }}
          variant="body2"
        >
          {daysRemaining}
        </Typography>{' '}
        day{daysRemaining !== 1 ? 's' : ''}
      </Typography>
      <Button
        color="primary"
        onClick={() => {
          navigate(paths.settings.billing);
        }}
        size="small"
        sx={{ minWidth: 'max-content' }}
        variant="contained"
      >
        Buy now
      </Button>
    </Stack>
  );
}

export default TrialStatus;
