import React from 'react';
import { Toaster as SonnerToaster, toast } from 'sonner';

function Toaster(): React.JSX.Element {
  return (
    <SonnerToaster
      position="bottom-left"
      toastOptions={{
        style: {
          fontSize: '1.2rem',
          padding: '1rem',
        },
      }}
    />
  );
}

export { toast, Toaster };
