import * as React from 'react';
import {
  useCreateTimeEntry,
  useDeleteTimeEntry,
  useGetTimeEntries,
  useGetTimeEntriesByJobId,
  useUpdateTimeEntry,
} from '@sity-ai/api';
import type { NewTimeEntry, TimeEntry } from '@sity-ai/types';
import { useQueryClient } from '@tanstack/react-query';

import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

export interface TimeEntriesResponse {
  timeEntries: TimeEntry[];
  loading: boolean;
  error: Error | null;
  refreshTimeEntryList: () => void;
  deleteTimeEntry: (timeEntryID: number) => Promise<boolean>;
  updateTimeEntry: (timeEntry: TimeEntry) => Promise<TimeEntry>;
  createTimeEntry: (timeEntry: NewTimeEntry) => Promise<TimeEntry>;
}

interface UseTimeEntriesArgs {
  jobID?: number;
  visitID?: number;
}

export function useTimeEntries(props?: UseTimeEntriesArgs): TimeEntriesResponse {
  const { jobID, visitID } = props ?? {};
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  // State to store time entries
  const [timeEntries, setTimeEntries] = React.useState<TimeEntry[]>([]);

  // Fetch all time entries
  const {
    data: allTimeEntries = [],
    isLoading: allTimeEntriesLoading,
    error: allTimeEntriesError,
  } = useGetTimeEntries({ companyID }, token, {
    enabled: !!companyID && !!token && !jobID,
    queryKey: [companyID, token],
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  // Fetch time entries by job ID
  const {
    data: timeEntriesByJobID = [],
    isLoading: timeEntriesByJobIDLoading,
    error: timeEntriesByJobIDError,
  } = useGetTimeEntriesByJobId({ companyID, jobID: jobID?.toString() || '' }, token, {
    enabled: !!companyID && !!token && !!jobID,
    queryKey: [companyID, token, jobID],
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  // Combine time entries
  React.useEffect(() => {
    setTimeEntries((prev) => {
      const newEntries = visitID ? timeEntriesByJobID : jobID ? timeEntriesByJobID?.body ?? [] : allTimeEntries;
      if (JSON.stringify(prev) !== JSON.stringify(newEntries)) return newEntries;
      return prev;
    });
  }, [allTimeEntries, timeEntriesByJobID, visitID, jobID]);

  // Imperatively refresh time entry list
  const refreshTimeEntryList = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getTimeEntries'] });
    queryClient.invalidateQueries({ queryKey: ['getTimeEntriesByJobId'] });
  };

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: handleDeleteTimeEntry,
    isLoading: isDeletingTimeEntry,
    error: deleteTimeEntryError,
  } = useDeleteTimeEntry({
    onSuccess: refreshTimeEntryList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleUpdateTimeEntry,
    isLoading: isUpdatingTimeEntry,
    error: updateTimeEntryError,
  } = useUpdateTimeEntry({
    onSuccess: refreshTimeEntryList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleCreateTimeEntry,
    isLoading: isCreatingTimeEntry,
    error: createTimeEntryError,
  } = useCreateTimeEntry({
    onSuccess: refreshTimeEntryList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/
  const deleteTimeEntry = React.useCallback(
    async (timeEntryID: number): Promise<boolean> => {
      const status = await handleDeleteTimeEntry({ params: { timeEntryID, companyID }, token });
      return status < 400;
    },
    [handleDeleteTimeEntry, token]
  );

  const updateTimeEntry = React.useCallback(
    async (timeEntry: TimeEntry): Promise<TimeEntry> => {
      return await handleUpdateTimeEntry({
        params: { timeEntryID: timeEntry.timeEntryID.toString() },
        timeEntry,
        token,
      });
    },
    [handleUpdateTimeEntry, token]
  );

  const createTimeEntry = React.useCallback(
    async (timeEntry: NewTimeEntry): Promise<TimeEntry> => {
      return await handleCreateTimeEntry({ params: timeEntry, token });
    },
    [handleCreateTimeEntry, token]
  );

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const loading = React.useMemo(() => {
    return (
      allTimeEntriesLoading ||
      timeEntriesByJobIDLoading ||
      isDeletingTimeEntry ||
      isUpdatingTimeEntry ||
      isCreatingTimeEntry
    );
  }, [allTimeEntriesLoading, timeEntriesByJobIDLoading, isDeletingTimeEntry, isUpdatingTimeEntry, isCreatingTimeEntry]);
  const error = React.useMemo(() => {
    return (
      allTimeEntriesError ||
      timeEntriesByJobIDError ||
      deleteTimeEntryError ||
      updateTimeEntryError ||
      createTimeEntryError
    );
  }, [allTimeEntriesError, timeEntriesByJobIDError, deleteTimeEntryError, updateTimeEntryError, createTimeEntryError]);

  return {
    timeEntries,
    loading,
    error,
    refreshTimeEntryList,
    deleteTimeEntry,
    updateTimeEntry,
    createTimeEntry,
  };
}
