import * as React from 'react';
import { useCreateTaxRate, useDeleteTaxRate, useGetTaxRates, useUpdateTaxRate } from '@sity-ai/api';
import type { TaxRate } from '@sity-ai/types';
import { useQueryClient } from '@tanstack/react-query';

import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

export interface TaxRatesResponse {
  taxRates: TaxRate[];
  loading: boolean;
  error: Error | null;
  refreshtaxRatesList: () => void;
  createTaxRate: (taxRate: TaxRate) => Promise<TaxRate>;
  updateTaxRate: (taxRate: TaxRate) => Promise<TaxRate>;
  deleteTaxRate: (taxRateID: number) => Promise<boolean>;
}

export function useTaxRates(): TaxRatesResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  // Fetch tax rates
  const {
    data: taxRates = [],
    isLoading: taxRatesLoading,
    error: taxRatesError,
  } = useGetTaxRates({ companyID }, token, {
    enabled: !!companyID && !!token,
    queryKey: [companyID, token],
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  // Imperatively refresh tax rates list
  const refreshtaxRatesList = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getTaxRates'] });
  };

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: handleCreateTaxRate,
    isLoading: isCreatingTaxRate,
    error: createTaxRateError,
  } = useCreateTaxRate({
    onSuccess: refreshtaxRatesList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleUpdateTaxRate,
    isLoading: isUpdatingTaxRate,
    error: updateTaxRateError,
  } = useUpdateTaxRate({
    onSuccess: refreshtaxRatesList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleDeleteTaxRate,
    isLoading: isDeletingTaxRate,
    error: deleteTaxRateError,
  } = useDeleteTaxRate({
    onSuccess: refreshtaxRatesList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/
  const createTaxRate = React.useCallback(
    async (taxRate: TaxRate): Promise<TaxRate> => {
      return await handleCreateTaxRate({ params: { ...taxRate, companyID }, token });
    },
    [handleCreateTaxRate, companyID, token]
  );
  const updateTaxRate = React.useCallback(
    async (taxRate: TaxRate): Promise<TaxRate> => {
      return await handleUpdateTaxRate({ params: { ...taxRate, companyID }, token });
    },
    [handleUpdateTaxRate, companyID, token]
  );

  const deleteTaxRate = React.useCallback(
    async (taxRateID: number): Promise<boolean> => {
      const status = await handleDeleteTaxRate({ params: { taxRateID, companyID }, token });
      return status < 400;
    },
    [handleDeleteTaxRate, companyID, token]
  );

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const loading = React.useMemo(() => {
    return taxRatesLoading || isCreatingTaxRate || isUpdatingTaxRate || isDeletingTaxRate;
  }, [taxRatesLoading, isCreatingTaxRate, isUpdatingTaxRate, isDeletingTaxRate]);
  const error = React.useMemo(() => {
    return taxRatesError || createTaxRateError || updateTaxRateError || deleteTaxRateError;
  }, [taxRatesError, createTaxRateError, updateTaxRateError, deleteTaxRateError]);

  return {
    taxRates,
    loading,
    error,
    refreshtaxRatesList,
    createTaxRate,
    updateTaxRate,
    deleteTaxRate,
  };
}
