import * as React from 'react';
import type { StripeCustomerSubscription } from '@sity-ai/api';
import { useCreateStripeSession, useGetStripeSubscriptionDetails } from '@sity-ai/api';
import type { Company } from '@sity-ai/types';

import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

import { toast } from '@/components/core/toaster';

interface Subscription {
  subscription: StripeCustomerSubscription | null;
  loading: boolean;
  loaded: boolean;
  error: Error | null;
  createStripeSession: (returnUrl?: string) => Promise<void>;
}

export function useStripeSubscription(company: Company | null): Subscription {
  const { token } = useUserContext();
  const stripeCustomerId = company?.stripeCustomerID ?? '';
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const {
    data: subscription,
    isLoading: loading,
    error: error,
  } = useGetStripeSubscriptionDetails({ stripeCustomerId }, token, {
    enabled: !!stripeCustomerId && !!token,
    onError: (error: Error) => {
      logger.error(error);
    },
    onSuccess: () => {
      setLoaded(true);
    },
  });

  // Create Stripe session mutation
  const { mutateAsync: handleCreateStripeSession } = useCreateStripeSession({
    onSuccess: (stripeSession: { url: string }) => {
      window.location.href = stripeSession.url;
    },
    onError: (error: Error) => {
      logger.error(error);
      toast.error(`Failed to create Stripe session: ${error.message}`);
    },
  });

  // Create Stripe session handler
  const createStripeSession = (returnUrl?: string): Promise<void> => {
    return handleCreateStripeSession({
      params: { customer: stripeCustomerId, returnUrl: returnUrl ?? undefined },
      stripeKey: '',
    });
  };

  return { subscription, loading, loaded, error, createStripeSession };
}
