import { CompanyHours, DAYS_OF_WEEK, NewCompanyHours } from '@sity-ai/types';
import dayjs, { type Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { type WorkingHoursField } from '@/components/core/form-fields/working-hours/types';

// Takes a plain time string (e.g. "12:00") and returns a Dayjs object
export function formatTimeAsDateTime(time: string, timeZone: string): Dayjs {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const today = dayjs().format('YYYY-MM-DD');
  const selectedDateTime = dayjs(`${today} ${time}`, 'YYYY-MM-DD HH:mm');

  return selectedDateTime.tz(timeZone);
}

// Takes a plain time string (e.g. "12:00") and returns a datetime string (e.g. "1970-01-01T12:00:00")
export function formatTimeAsDateTimeString(time: string, timeZone: string): string {
  const dateTime = formatTimeAsDateTime(time, timeZone);
  return dateTime.toISOString();
}

export function createDefaultHours(companyID: number): NewCompanyHours[] {
  return DAYS_OF_WEEK.map((day) => ({
    companyID,
    dayOfWeek: day.index,
    openTime: '09:00',
    closeTime: '17:00',
    rangeID: 1,
    isClosed: day.key === 'sun' || day.key === 'sat',
  }));
}

interface ParseCompanyHoursForFormProps {
  companyID: number;
  companyHours: Record<number, CompanyHours[]>;
  companyTimeZone: string | undefined;
}
export function parseCompanyHoursForForm({
  companyID,
  companyHours,
  companyTimeZone,
}: ParseCompanyHoursForFormProps): NewCompanyHours[] {
  if (!companyTimeZone || !companyHours) return [];
  dayjs.extend(utc);
  dayjs.extend(timezone);

  // Parse the existing company hours
  const parsedHours: NewCompanyHours[] = Object.values(companyHours).flatMap((hours) =>
    hours.map((hour) => {
      const openTime = dayjs.tz(hour.openTime, companyTimeZone).second(0).format('HH:mm:ss');
      const closeTime = dayjs.tz(hour.closeTime, companyTimeZone).second(0).format('HH:mm:ss');
      return {
        companyID: hour.companyID,
        dayOfWeek: Number(hour.dayOfWeek),
        openTime,
        closeTime,
        rangeID: hour.rangeID,
        hoursID: hour.hoursID,
      };
    })
  );

  // Add empty records for days of the week that don't have any hours
  DAYS_OF_WEEK.forEach((day) => {
    if (!parsedHours.find((hour) => hour.dayOfWeek === day.index)) {
      parsedHours.push({
        companyID,
        dayOfWeek: day.index,
        openTime: '00:00',
        closeTime: '00:00',
        rangeID: 1,
      });
    }
  });

  return parsedHours;
}

export function parseFormDataForEngine(
  companyHours: WorkingHoursField[],
  companyID: number,
  companyTimeZone: string
): NewCompanyHours[] {
  const newCompanyHours: NewCompanyHours[] = [];
  companyHours
    .filter((day) => day.ranges?.length && day.ranges.length > 0)
    .forEach((day) => {
      day.ranges
        ?.filter((range) => range.openTime !== '00:00' || range.closeTime !== '00:00')
        ?.sort((a, b) => {
          const aTime = formatTimeAsDateTime(a.openTime, companyTimeZone);
          const bTime = formatTimeAsDateTime(b.openTime, companyTimeZone);
          return aTime.isBefore(bTime) ? -1 : aTime.isAfter(bTime) ? 1 : 0;
        })
        ?.forEach((range, index) => {
          newCompanyHours.push({
            closeTime: formatTimeAsDateTime(range.closeTime, companyTimeZone).format('HH:mm:ss'),
            companyID,
            dayOfWeek: day.dayOfWeek,
            openTime: formatTimeAsDateTime(range.openTime, companyTimeZone).format('HH:mm:ss'),
            rangeID: range.rangeID,
            hoursID: range.hoursID,
          });
        });
    });

  return newCompanyHours;
}
