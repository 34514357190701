import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { paths } from '@/paths';

import { ClientsProvider } from '@/contexts/clients-context';
import { InvoicesProvider } from '@/contexts/invoices-context';
import { JobLineItemsProvider } from '@/contexts/job-line-items-context';
import { PaymentsProvider } from '@/contexts/payments-context';
import { PricebookItemsProvider } from '@/contexts/pricebook-items-context';
import { ServiceRecordsProvider } from '@/contexts/service-records-context';
import { TimeEntriesProvider } from '@/contexts/time-entries-context';

import { logError } from '@/utils/log-error';

import { ErrorFallback } from '@/components/core/error-fallback';
import { Layout } from '@/components/hub/layout/layout';
import { ProtectedPage } from '@/components/hub/layout/protected-page';

export const route: RouteObject = {
  element: (
    <Layout>
      <Outlet />
    </Layout>
  ),
  children: [
    {
      path: 'jobs',
      index: true,
      lazy: async () => {
        const { Page } = await import('@/pages/jobs/list');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2, 3, 4]}>
                  <ClientsProvider>
                    <ServiceRecordsProvider>
                      <Page />
                    </ServiceRecordsProvider>
                  </ClientsProvider>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: '/jobs/create',
      lazy: async () => {
        const { Page } = await import('@/pages/jobs/create');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2]}>
                  <ClientsProvider>
                    <ServiceRecordsProvider>
                      <PricebookItemsProvider>
                        <Page />
                      </PricebookItemsProvider>
                    </ServiceRecordsProvider>
                  </ClientsProvider>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: '/jobs/:jobID',
      lazy: async () => {
        const { Page } = await import('@/pages/jobs/details');
        return {
          Component: () => {
            const navigate = useNavigate();
            const { jobID } = useParams();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.jobs.list);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2, 3, 4]}>
                  <ServiceRecordsProvider jobID={parseInt(jobID || '0')}>
                    <ClientsProvider>
                      <PricebookItemsProvider>
                        <TimeEntriesProvider jobID={parseInt(jobID || '0')}>
                          <JobLineItemsProvider jobID={parseInt(jobID || '0')}>
                            <InvoicesProvider>
                              <PaymentsProvider>
                                <Page />
                              </PaymentsProvider>
                            </InvoicesProvider>
                          </JobLineItemsProvider>
                        </TimeEntriesProvider>
                      </PricebookItemsProvider>
                    </ClientsProvider>
                  </ServiceRecordsProvider>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: '/jobs/:jobID/visits/:visitID',
      lazy: async () => {
        const { Page } = await import('@/pages/visits/details');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.jobs.list);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2, 3]}>
                  <ServiceRecordsProvider>
                    <ClientsProvider>
                      <PricebookItemsProvider>
                        <Page />
                      </PricebookItemsProvider>
                    </ClientsProvider>
                  </ServiceRecordsProvider>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: '/jobs/:jobID/visits/:visitID/edit',
      lazy: async () => {
        const { Page } = await import('@/pages/visits/create');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.jobs.list);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2]}>
                  <ServiceRecordsProvider>
                    <ClientsProvider>
                      <PricebookItemsProvider>
                        <Page />
                      </PricebookItemsProvider>
                    </ClientsProvider>
                  </ServiceRecordsProvider>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
  ],
};
