import * as React from 'react';
import type { JobLineItem, NewJobLineItem } from '@sity-ai/types';

import { useJobLineItems, type JobLineItemsResponse } from '@/hooks/use-job-line-items';

/*****************************************************************************
 * Types
 *****************************************************************************/
export interface JobLineItemsContextValue extends JobLineItemsResponse {
  createJobLineItem: (jobLineItem: NewJobLineItem) => Promise<JobLineItem>;
  selectedJobLineItem: JobLineItem | null;
  selectJobLineItem: (jobLineItemID: number) => void;
  clearSelectedJobLineItem: () => void;
}
export interface JobLineItemsProviderProps {
  children: React.ReactNode;
  jobID: number;
}

/*****************************************************************************
 * Context
 *****************************************************************************/
export const JobLineItemsContext = React.createContext<JobLineItemsContextValue>({
  jobLineItems: [],
  loading: false,
  error: null,
  refreshJobLineItemList: () => undefined,
  deleteJobLineItem: () => Promise.resolve(false),
  updateJobLineItem: () => Promise.resolve({} as JobLineItem),
  createJobLineItem: () => Promise.resolve({} as JobLineItem),
  selectedJobLineItem: null,
  selectJobLineItem: () => undefined,
  clearSelectedJobLineItem: () => undefined,
});

export function useJobLineItemsContext(): JobLineItemsContextValue {
  const context = React.useContext(JobLineItemsContext);
  if (!context) {
    throw new Error('useJobLineItemsContext must be used within a JobLineItemsProvider');
  }
  return context;
}

/*****************************************************************************
 * Provider
 *****************************************************************************/
export function JobLineItemsProvider({ children, jobID }: JobLineItemsProviderProps): React.JSX.Element {
  const jobLineItems = useJobLineItems(jobID);

  const [selectedJobLineItem, setSelectedJobLineItem] = React.useState<JobLineItem | null>(null);
  const selectJobLineItem = (jobLineItemID: number): void => {
    const jobLineItem = jobLineItems.jobLineItems.find((jobLineItem) => jobLineItem.jobItemID === jobLineItemID);
    if (jobLineItem) setSelectedJobLineItem(jobLineItem);
  };
  const clearSelectedJobLineItem = (): void => {
    setSelectedJobLineItem(null);
  };

  const contextValue = React.useMemo(
    () => ({
      ...jobLineItems,
      selectedJobLineItem,
      selectJobLineItem,
      clearSelectedJobLineItem,
    }),
    [jobLineItems, selectedJobLineItem, selectJobLineItem, clearSelectedJobLineItem]
  );

  return <JobLineItemsContext.Provider value={contextValue}>{children}</JobLineItemsContext.Provider>;
}
