import * as React from 'react';
import { Bell as BellIcon } from '@phosphor-icons/react/Bell';
import { Buildings as BuildingIcon } from '@phosphor-icons/react/Buildings';
import { Calculator as TaxIcon } from '@phosphor-icons/react/Calculator';
import { CreditCard as CreditCardIcon } from '@phosphor-icons/react/CreditCard';
import { Gear as GearIcon } from '@phosphor-icons/react/Gear';
import { LockKey as LockKeyIcon } from '@phosphor-icons/react/LockKey';
import { PlugsConnected as PlugsConnectedIcon } from '@phosphor-icons/react/PlugsConnected';
import { UserCircle as UserCircleIcon } from '@phosphor-icons/react/UserCircle';
import { UsersThree as UsersThreeIcon } from '@phosphor-icons/react/UsersThree';
import type { NavItemConfig } from '@sity-ai/types';

import { paths } from '@/paths';

import { useAuthorization } from '@/hooks/authorization/use-authorization';

import { SideNav } from '@/components/core/side-nav/side-nav';

export function SettingsNav(): React.JSX.Element {
  const { hasPermission } = useAuthorization();

  const icons = {
    'credit-card': CreditCardIcon,
    building: BuildingIcon,
    'lock-key': LockKeyIcon,
    'plugs-connected': PlugsConnectedIcon,
    'user-circle': UserCircleIcon,
    'users-three': UsersThreeIcon,
    calculator: TaxIcon,
    gear: GearIcon,
    bell: BellIcon,
  };

  const navItems: NavItemConfig[] = [
    {
      items: [
        {
          href: paths.settings.account,
          icon: 'user-circle',
          items: undefined,
          key: 'account',
          title: 'My profile',
        },
        {
          href: paths.settings.security,
          icon: 'lock-key',
          items: undefined,
          key: 'security',
          title: 'Security',
        },
      ],
      key: 'personal',
      title: 'Personal',
    },
    {
      items: [
        {
          href: paths.settings.company,
          icon: 'building',
          items: undefined,
          key: 'company',
          title: 'Company profile',
        },
        {
          href: paths.settings.team,
          icon: 'users-three',
          items: undefined,
          key: 'team',
          title: 'Manage team',
        },
        ...(hasPermission('billing', 'read')
          ? [
              {
                href: paths.settings.billing,
                icon: 'credit-card',
                items: undefined,
                key: 'billing',
                title: 'Billing & plans',
              } as NavItemConfig,
            ]
          : []),
      ],
      key: 'organization',
      title: 'Organization',
    },
    {
      items: [
        {
          href: paths.settings.appSettings,
          icon: 'gear',
          items: undefined,
          key: 'app-setting',
          title: 'App settings',
        },
      ],
      key: 'application',
      title: 'Application',
    },
  ];

  return <SideNav iconsMap={icons} navItems={navItems} />;
}
