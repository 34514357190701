import * as React from 'react';
import {
  useDeleteJob,
  useDeleteVisit,
  useGetClients,
  useGetServiceRecords,
  useUpdateJob,
  useUpdateVisit,
} from '@sity-ai/api';
import type { Client, JobDetails, ServiceRecord, Visit, VisitWithJobAssignment } from '@sity-ai/types';
import { useQueryClient } from '@tanstack/react-query';

import { dayjs } from '@/lib/dayjs';
import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface CloseActiveServiceRecordOptions {
  closeJobWithIncompleteVisits?: boolean;
}
const CLOSE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS = {
  closeJobWithIncompleteVisits: false,
};

interface CompleteActiveServiceRecordOpts {
  deleteFutureVisits?: boolean;
}
const COMPLETE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS = {
  deleteFutureVisits: false,
};

export interface ActiveServiceRecordActions {
  closeActiveServiceRecord: (opts?: CloseActiveServiceRecordOptions) => Promise<boolean>;
  archiveActiveServiceRecord: () => Promise<boolean>;
  removeAllVisitsFromActiveServiceRecord: () => Promise<boolean>;
  completeAllPastVisitsForActiveServiceRecord: (opts?: CompleteActiveServiceRecordOpts) => Promise<boolean>;
  deleteServiceRecord: (jobID: number) => Promise<boolean>;
  updateJobDetails: (jobID: number, jobDetails: JobDetails) => Promise<JobDetails | null>;
  updateVisit: (visitDetails: Visit) => Promise<VisitWithJobAssignment | null>;
  updateServiceRecord: (jobID: number, updatedServiceRecord: ServiceRecord) => Promise<ServiceRecord | null>;
}

interface ServiceRecordsMethods {
  error: Error | null;
  loading: boolean;
  loaded: boolean;
  refreshServiceRecordList: () => void;
  serviceRecord: ServiceRecord | null;
  serviceRecords: ServiceRecord[];
  setActiveServiceRecord: (jobID: number) => ServiceRecord | null;
  serviceRecordActions: ActiveServiceRecordActions;
}

export function useServiceRecords(): ServiceRecordsMethods {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  const [serviceRecords, setServiceRecords] = React.useState<ServiceRecord[]>([]);
  const [serviceRecord, setServiceRecord] = React.useState<ServiceRecord | null>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  // Get a list of all service records
  const {
    data: allServiceRecordsResponse,
    isPending: allServiceRecordsLoading,
    error: allServiceRecordsError,
  } = useGetServiceRecords({ companyID }, token, {
    enabled: !!companyID && !!token,
    queryKey: [companyID, token],
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  // Get a list of all clients
  const {
    data: allClientsResponse,
    isPending: allClientsLoading,
    error: allClientsError,
  } = useGetClients({ companyID }, token, {
    enabled: !!companyID && !!token,
    queryKey: [companyID, token],
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  // Combine service records and clients
  React.useEffect(() => {
    if (!allServiceRecordsResponse || !allClientsResponse) return;

    const combinedRecords = allServiceRecordsResponse.map((sr: ServiceRecord) => {
      const client = allClientsResponse.find((c: Client) => c.clientID === sr.clientID);
      return { ...sr, client } as ServiceRecord;
    });

    setServiceRecords((prevRecords: ServiceRecord[]) => {
      const isSame =
        prevRecords.length === combinedRecords.length &&
        prevRecords.every((record, index) => record.jobID === combinedRecords[index].jobID);

      if (isSame) return prevRecords;
      return combinedRecords;
    });

    const activeServiceRecord = combinedRecords.find((sr: ServiceRecord) => sr.jobID === serviceRecord?.jobID);
    if (activeServiceRecord) setServiceRecord(activeServiceRecord);

    setLoaded(true);
  }, [allServiceRecordsResponse, allClientsResponse]);

  // Imperative function to refresh the client/service record list
  const refreshServiceRecordList = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getServiceRecords'] });
    queryClient.invalidateQueries({ queryKey: ['getClients'] });
  };

  // Imperative function to set the active service record
  const setActiveServiceRecord = (jobID: number): ServiceRecord | null => {
    const record = serviceRecords.find((sr) => sr.jobID === jobID)!;
    if (!record) return null;
    setServiceRecord(record);
    return record;
  };

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: handleCloseServiceRecord,
    isPending: closeServiceRecordLoading,
    error: closeServiceRecordError,
  } = useUpdateJob({
    onSuccess: refreshServiceRecordList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleArchiveServiceRecord,
    isPending: archiveServiceRecordLoading,
    error: archiveServiceRecordError,
  } = useUpdateJob({
    enabled: !!companyID && !!token,
    onSuccess: refreshServiceRecordList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleRemoveAllVisitsFromServiceRecord,
    isPending: removeAllVisitsFromServiceRecordLoading,
    error: removeAllVisitsFromServiceRecordError,
  } = useDeleteVisit({
    enabled: !!companyID && !!token,
    onSuccess: refreshServiceRecordList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleUpdateVisit,
    isPending: updateVisitLoading,
    error: updateVisitError,
  } = useUpdateVisit({
    onSuccess: refreshServiceRecordList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleDeleteVisit,
    isPending: deleteVisitLoading,
    error: deleteVisitError,
  } = useDeleteVisit({
    enabled: !!companyID && !!token,
    onSuccess: refreshServiceRecordList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleDeleteServiceRecord,
    isPending: deleteServiceRecordLoading,
    error: deleteServiceRecordError,
  } = useDeleteJob({
    onSuccess: refreshServiceRecordList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleUpdateJobDetails,
    isPending: updateJobDetailsLoading,
    error: updateJobDetailsError,
  } = useUpdateJob({
    onSuccess: refreshServiceRecordList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleUpdateServiceRecord,
    isPending: updateServiceRecordLoading,
    error: updateServiceRecordError,
  } = useUpdateJob({
    onSuccess: refreshServiceRecordList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/
  const closeActiveServiceRecord = React.useCallback(
    async (opts: CloseActiveServiceRecordOptions = CLOSE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS): Promise<boolean> => {
      const allVisitsComplete = serviceRecord?.visits.every((visit) => visit.isComplete);
      if (!allVisitsComplete && !opts.closeJobWithIncompleteVisits) return false;

      const response = await handleCloseServiceRecord(
        { params: { jobID: serviceRecord?.jobID?.toString(), companyID }, jobDetails: { jobStatusID: 6 } },
        token
      );
      return response.jobStatusID === 6;
    },
    [serviceRecord, companyID, token]
  );

  const archiveActiveServiceRecord = React.useCallback(async (): Promise<boolean> => {
    if (!serviceRecord) return false;
    const response = await handleArchiveServiceRecord(
      { params: { jobID: serviceRecord?.jobID?.toString(), companyID }, jobDetails: { jobStatusID: 7 } },
      token
    );
    return response.jobStatusID === 7;
  }, [serviceRecord, companyID, token]);

  const removeAllVisitsFromActiveServiceRecord = React.useCallback(async (): Promise<boolean> => {
    if (!serviceRecord) return false;
    const status = await handleRemoveAllVisitsFromServiceRecord({
      params: { jobID: serviceRecord?.jobID?.toString(), companyID },
      token,
    });
    return status < 400;
  }, [serviceRecord, companyID, token]);

  const completeAllPastVisitsForActiveServiceRecord = React.useCallback(
    async (opts: CompleteActiveServiceRecordOpts = COMPLETE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS): Promise<boolean> => {
      if (!serviceRecord) return false;

      const today = dayjs();
      const pastVisits: Visit[] = [];
      const futureVisits: Visit[] = [];
      serviceRecord.visits.forEach((visit) => {
        if (visit.endDate && dayjs(visit.endDate).isBefore(today)) pastVisits.push(visit);
        else if (opts.deleteFutureVisits) futureVisits.push(visit);
      });

      pastVisits.forEach((visit) => {
        handleUpdateVisit({
          params: { jobID: serviceRecord?.jobID?.toString(), companyID, visitID: visit.visitID?.toString() },
          visit: { ...visit, isComplete: true },
          token,
        });
      });

      futureVisits.forEach((visit) => {
        handleDeleteVisit({
          params: { visitID: visit.visitID?.toString(), companyID },
          token,
        });
      });

      return true;
    },
    [serviceRecord, companyID, token]
  );

  const updateVisit = React.useCallback(
    async (visitDetails: Visit): Promise<VisitWithJobAssignment | null> => {
      if (!visitDetails.jobID && !serviceRecord) return null;
      return await handleUpdateVisit({
        params: {
          jobID: visitDetails.jobID?.toString() || serviceRecord?.jobID?.toString(),
          companyID,
          visitID: visitDetails.visitID?.toString(),
        },
        visit: visitDetails,
        token,
      });
    },
    [serviceRecord, companyID, token]
  );

  const deleteServiceRecord = React.useCallback(
    async (jobID: number): Promise<boolean> => {
      if (!jobID && !serviceRecord) return false;
      const status = await handleDeleteServiceRecord({
        params: { jobID: jobID?.toString() || serviceRecord?.jobID?.toString(), companyID },
        token,
      });
      return status < 400;
    },
    [serviceRecord, companyID, token]
  );

  const updateJobDetails = React.useCallback(
    async (jobID: number, jobDetails: JobDetails): Promise<JobDetails | null> => {
      if (!jobID && !serviceRecord) return null;
      return await handleUpdateJobDetails({
        params: { jobID: jobID?.toString() || serviceRecord?.jobID?.toString(), companyID },
        jobDetails,
        token,
      });
    },
    [serviceRecord, companyID, token]
  );

  const updateServiceRecord = React.useCallback(
    async (jobID: number, updatedServiceRecord: ServiceRecord): Promise<ServiceRecord | null> => {
      if (!jobID && !serviceRecord) return null;
      return await handleUpdateServiceRecord({
        params: { jobID: jobID?.toString() || serviceRecord?.jobID?.toString(), companyID },
        jobDetails: updatedServiceRecord,
        token,
      });
    },
    [serviceRecord, companyID, token]
  );

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const error = React.useMemo(() => {
    return (
      allServiceRecordsError ||
      allClientsError ||
      closeServiceRecordError ||
      archiveServiceRecordError ||
      removeAllVisitsFromServiceRecordError ||
      updateVisitError ||
      deleteVisitError ||
      deleteServiceRecordError ||
      updateJobDetailsError ||
      updateServiceRecordError
    );
  }, [
    allServiceRecordsError,
    allClientsError,
    closeServiceRecordError,
    archiveServiceRecordError,
    removeAllVisitsFromServiceRecordError,
    updateVisitError,
    deleteVisitError,
    deleteServiceRecordError,
    updateJobDetailsError,
    updateServiceRecordError,
  ]);

  const loading = React.useMemo(() => {
    return (
      allServiceRecordsLoading ||
      allClientsLoading ||
      closeServiceRecordLoading ||
      archiveServiceRecordLoading ||
      removeAllVisitsFromServiceRecordLoading ||
      updateVisitLoading ||
      deleteVisitLoading ||
      deleteServiceRecordLoading ||
      updateJobDetailsLoading ||
      updateServiceRecordLoading
    );
  }, [
    allServiceRecordsLoading,
    allClientsLoading,
    closeServiceRecordLoading,
    archiveServiceRecordLoading,
    removeAllVisitsFromServiceRecordLoading,
    updateVisitLoading,
    deleteVisitLoading,
    deleteServiceRecordLoading,
    updateJobDetailsLoading,
    updateServiceRecordLoading,
  ]);

  return {
    error,
    loaded,
    loading,
    refreshServiceRecordList,
    serviceRecord,
    serviceRecordActions: {
      closeActiveServiceRecord,
      archiveActiveServiceRecord,
      removeAllVisitsFromActiveServiceRecord,
      completeAllPastVisitsForActiveServiceRecord,
      deleteServiceRecord,
      updateJobDetails,
      updateServiceRecord,
      updateVisit,
    },
    serviceRecords,
    setActiveServiceRecord,
  };
}
