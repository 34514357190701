import * as React from 'react';
import { useCreateCompanyHours, useDeleteCompanyHours, useGetCompanyHours, useUpdateCompanyHours } from '@sity-ai/api';
import type { CompanyHours, NewCompanyHours } from '@sity-ai/types';
import { useQueryClient } from '@tanstack/react-query';
import type { Dayjs } from 'dayjs';
import dayjs, { isDayjs } from 'dayjs';

import { logger } from '@/lib/default-logger';
import DAYS_OF_THE_WEEK from '@/constants/days-of-the-week.json';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface HoursResponse {
  error: Error | null;
  refresh: () => void;
  getHoursByDate: (day: Dayjs | Date) => CompanyHours | null;
  getLatestHours: (hours: CompanyHours[]) => CompanyHours[];
  hours: Record<number, CompanyHours[]>;
  isDuringBusinessHours: (date: Date | Dayjs) => boolean;
  isBusinessDay: (date: Date | Dayjs) => boolean;
  loading: boolean;
  createCompanyHours: (newCompanyHours: NewCompanyHours) => Promise<CompanyHours>;
  updateCompanyHours: (companyHours: CompanyHours) => Promise<CompanyHours>;
  deleteCompanyHours: (companyHours: CompanyHours) => Promise<void>;
}

function getLatestHours(hours: CompanyHours[]): CompanyHours[] {
  return hours
    .map((record) => {
      const order = DAYS_OF_THE_WEEK.find((day) => day.order === record.dayOfWeek)?.order ?? -1;
      return { ...record, order };
    })
    .reduce((hoursList: CompanyHours[], currentRecord: CompanyHours) => {
      const existingObjectIndex = hoursList.findIndex((obj) => obj.dayOfWeek === currentRecord.dayOfWeek);

      if (existingObjectIndex !== -1) {
        const existingObject = hoursList[existingObjectIndex];
        if (dayjs(currentRecord.createdAt).isAfter(dayjs(existingObject.createdAt))) {
          hoursList[existingObjectIndex] = currentRecord;
        }
      } else hoursList.push(currentRecord);

      return hoursList;
    }, [])
    .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
}

function getHours(date: Date | Dayjs, hours: CompanyHours[]): CompanyHours | null {
  const today = isDayjs(date) ? date : dayjs(date);
  const todaysHours = hours.find((hoursRecord) => hoursRecord.dayOfWeek === today.day());
  return todaysHours ?? null;
}

export function useCompanyHours(): HoursResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  // Fetch company hours
  const {
    data: hours = {},
    isLoading: companyHoursLoading,
    error: companyHoursError,
  } = useGetCompanyHours({ companyID }, token, { queryKey: [companyID, token] });

  // Imperatively refresh company hours
  const refresh = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getCompanyHours'] });
  };

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: handleCreateCompanyHours,
    isLoading: createCompanyHoursLoading,
    error: createCompanyHoursError,
  } = useCreateCompanyHours({
    onSuccess: refresh,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleUpdateCompanyHours,
    isLoading: updateCompanyHoursLoading,
    error: updateCompanyHoursError,
  } = useUpdateCompanyHours({
    onSuccess: refresh,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleDeleteCompanyHours,
    isLoading: deleteCompanyHoursLoading,
    error: deleteCompanyHoursError,
  } = useDeleteCompanyHours({
    onSuccess: refresh,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/

  // Get hours for a given date
  const getHoursByDate = (day: Dayjs | Date): CompanyHours | null => {
    return getHours(day, hours);
  };

  // Check if the given date is during business hours
  const isDuringBusinessHours = (date: Date | Dayjs): boolean => {
    const candidateAsDayjs = isDayjs(date) ? date : dayjs(date);
    const businessHours = getHoursByDate(date);
    if (businessHours === null) return false;

    // Get all times as minutes since the start of the day for ez comparison
    const candidate = candidateAsDayjs.hour() * 60 + candidateAsDayjs.minute();
    const openTime = dayjs(businessHours.openTime).hour() * 60 + dayjs(businessHours.openTime).minute();
    const closeTime = dayjs(businessHours.closeTime).hour() * 60 + dayjs(businessHours.closeTime).minute();

    if (businessHours.isClosed || candidate < openTime || candidate > closeTime) return false;
    return true;
  };

  // Check if the given date is a business day
  const isBusinessDay = (date: Date | Dayjs): boolean => {
    return getHoursByDate(date) !== null;
  };

  // Create company hours
  const createCompanyHours = React.useCallback(
    async (newCompanyHours: NewCompanyHours): Promise<CompanyHours> => {
      return await handleCreateCompanyHours({ params: { companyID }, newCompanyHours, token });
    },
    [handleCreateCompanyHours, companyID, token]
  );

  // Update company hours
  const updateCompanyHours = React.useCallback(
    async (companyHours: CompanyHours): Promise<CompanyHours> => {
      return await handleUpdateCompanyHours({ params: { companyID }, companyHours, token });
    },
    [handleUpdateCompanyHours, companyID, token]
  );

  // Delete company hours
  const deleteCompanyHours = React.useCallback(
    async (companyHours: CompanyHours): Promise<void> => {
      return await handleDeleteCompanyHours({ params: { companyID }, companyHours, token });
    },
    [handleDeleteCompanyHours, companyID, token]
  );

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const loading = React.useMemo(() => {
    return companyHoursLoading || createCompanyHoursLoading || updateCompanyHoursLoading || deleteCompanyHoursLoading;
  }, [companyHoursLoading, createCompanyHoursLoading, updateCompanyHoursLoading, deleteCompanyHoursLoading]);
  const error = React.useMemo(() => {
    return companyHoursError || createCompanyHoursError || updateCompanyHoursError || deleteCompanyHoursError;
  }, [companyHoursError, createCompanyHoursError, updateCompanyHoursError, deleteCompanyHoursError]);

  return {
    error,
    refresh,
    getHoursByDate,
    getLatestHours,
    hours,
    isDuringBusinessHours,
    isBusinessDay,
    loading,
    createCompanyHours,
    updateCompanyHours,
    deleteCompanyHours,
  };
}
