import * as React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import { config } from '@/config';

import { paths } from '@/paths';

import type { UserContextValue } from '@/contexts/auth/types';
import { useCurrentUser } from '@/hooks/use-current-user';

export const UserContext = React.createContext<UserContextValue | undefined>(undefined);

export interface UserProviderProps {
  children: React.ReactNode;
}

export function useUserContext(): UserContextValue {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}

function UserProviderImpl({ children }: UserProviderProps): React.JSX.Element {
  const userHook = useCurrentUser();
  const userContextValue: UserContextValue = React.useMemo(
    () => ({
      ...userHook,
      isLoading: userHook.loading,
      refreshUserData: userHook.refreshUser,
    }),
    [userHook]
  );

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
}

export function UserProvider({ children }: UserProviderProps): React.JSX.Element {
  return (
    <Auth0Provider
      authorizationParams={{ redirect_uri: `${window.location.origin}${paths.auth.auth0.callback}` }}
      cacheLocation="localstorage"
      clientId={config.auth0.clientId!}
      domain={config.auth0.domain!}
      skipRedirectCallback
    >
      <UserProviderImpl>{children}</UserProviderImpl>
    </Auth0Provider>
  );
}

export const UserConsumer = UserContext.Consumer;
