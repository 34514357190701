import * as React from 'react';
import {
  useCreateClient,
  useCreateProperty,
  useDeleteClient,
  useGetClientAndProperty,
  useUpdateClient,
} from '@sity-ai/api';
import type { Client, NewClient, NewProperty, Property } from '@sity-ai/types';
import { useQueryClient } from '@tanstack/react-query';

import { logger } from '@/lib/default-logger';

import { useUserContext } from '@/contexts/auth/auth0/user-context';

export interface ClientsResponse {
  clients: Client[];
  createClient: (client: NewClient) => Promise<Client>;
  createPropertyForClient: (property: NewProperty) => Promise<Property>;
  deleteClient: (clientID: string) => Promise<boolean>;
  deleteClients: (clientList: Client[]) => Promise<boolean[]>;
  error: Error | null;
  loading: boolean;
  refreshClientList: () => void;
  updateClient: (client: Client) => Promise<Client>;
}

export function useClients(): ClientsResponse {
  const { user, token } = useUserContext();
  const queryClient = useQueryClient();
  const companyID = user?.companyID?.toString() || '';

  // Fetch clients query
  const {
    data: clients = [],
    isLoading: fetchingClients,
    error: fetchClientsError,
  } = useGetClientAndProperty({ companyID }, token, {
    enabled: !!companyID && !!token,
    queryKey: [companyID, token],
  });

  // Imperatively refresh client list
  const refreshClientList = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getClientAndProperty'] });
  };

  /****************************************************************************
   * Mutations
   ***************************************************************************/
  const {
    mutateAsync: handleCreateClient,
    isLoading: createClientLoading,
    error: createClientError,
  } = useCreateClient({
    onSuccess: refreshClientList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleCreateProperty,
    isLoading: createPropertyLoading,
    error: createPropertyError,
  } = useCreateProperty({
    onSuccess: refreshClientList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleDeleteClient,
    isLoading: deleteClientLoading,
    error: deleteClientError,
  } = useDeleteClient({
    onSuccess: refreshClientList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const {
    mutateAsync: handleUpdateClient,
    isLoading: updateClientLoading,
    error: updateClientError,
  } = useUpdateClient({
    onSuccess: refreshClientList,
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  /****************************************************************************
   * Handlers
   ***************************************************************************/
  const createClient = React.useCallback(
    async (client: NewClient): Promise<Client> => {
      return await handleCreateClient({ params: client, token });
    },
    [handleCreateClient, token]
  );
  const createPropertyForClient = React.useCallback(
    async (property: NewProperty): Promise<Property> => {
      return await handleCreateProperty({ params: property, token });
    },
    [handleCreateProperty, token]
  );
  const deleteClient = React.useCallback(
    async (clientID: string): Promise<boolean> => {
      const status = await handleDeleteClient({
        params: { clientID: clientID || '' },
        token,
      });
      return status < 400;
    },
    [handleDeleteClient, token]
  );
  const deleteClients = async (clientList: Client[]): Promise<boolean[]> => {
    return await Promise.all(clientList.map((client) => deleteClient(client.clientID.toString())));
  };
  const updateClient = React.useCallback(
    async (updatedClient: Client): Promise<Client> => {
      return await handleUpdateClient({
        params: { clientID: updatedClient.clientID, companyID },
        client: updatedClient,
        token,
      });
    },
    [handleUpdateClient, companyID, token]
  );

  /****************************************************************************
   * Side effects
   ***************************************************************************/
  const loading = React.useMemo(() => {
    return (
      fetchingClients || deleteClientLoading || updateClientLoading || createClientLoading || createPropertyLoading
    );
  }, [fetchingClients, deleteClientLoading, updateClientLoading, createClientLoading, createPropertyLoading]);
  const error = React.useMemo(() => {
    return fetchClientsError || deleteClientError || updateClientError || createClientError || createPropertyError;
  }, [fetchClientsError, deleteClientError, updateClientError, createClientError, createPropertyError]);

  return {
    clients,
    createClient,
    createPropertyForClient,
    deleteClient,
    deleteClients,
    error,
    loading,
    refreshClientList,
    updateClient,
  };
}
