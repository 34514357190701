import * as React from 'react';
import type { Payment } from '@sity-ai/types';

import { usePayments, type PaymentsResponse } from '@/hooks/use-payments';

import { Loading } from '@/components/core/loading';

/*****************************************************************************
 * Types
 *****************************************************************************/
export interface PaymentsContextValue extends PaymentsResponse {
  selectedPayment: Payment | null;
  setSelectedPayment: (payment: Payment | null) => void;
  filteredPayments: Payment[];
  setFilteredPayments: (payments: Payment[]) => void;
}
export interface PaymentsProviderProps {
  children: React.ReactNode;
  clientID?: string;
  paymentID?: number;
  jobID?: number;
  invoiceID?: number;
}

/*****************************************************************************
 * Context
 *****************************************************************************/
export const PaymentsContext = React.createContext<PaymentsContextValue>({
  payments: [],
  loading: false,
  error: null,
  refreshPaymentsList: () => undefined,
  createNewPayment: async () => ({ paymentID: 0, totalAmount: 0, txnDate: new Date().toISOString() }) as Payment,
  updateExistingPayment: async () => ({ paymentID: 0, totalAmount: 0, txnDate: new Date().toISOString() }) as Payment,
  deleteExistingPayment: async () => false,
  selectedPayment: null,
  setSelectedPayment: () => undefined,
  filteredPayments: [],
  setFilteredPayments: () => undefined,
});

export function usePaymentsContext(): PaymentsContextValue {
  return React.useContext(PaymentsContext);
}

/*****************************************************************************
 * Provider
 *****************************************************************************/
export function PaymentsProvider({
  children,
  clientID,
  paymentID,
  jobID,
  invoiceID,
}: PaymentsProviderProps): React.JSX.Element {
  const numericClientID = clientID ? parseInt(clientID, 10) : undefined;
  const {
    payments,
    loading,
    error,
    refreshPaymentsList,
    createNewPayment,
    updateExistingPayment,
    deleteExistingPayment,
  } = usePayments(numericClientID);

  const [selectedPayment, setSelectedPayment] = React.useState<Payment | null>(null);
  const [filteredPayments, setFilteredPayments] = React.useState<Payment[]>([]);

  React.useEffect(() => {
    if (paymentID && payments.length > 0) {
      let filteredPayments = payments;
      if (jobID) filteredPayments = filteredPayments.filter((payment) => payment.jobID === jobID);
      if (invoiceID) filteredPayments = filteredPayments.filter((payment) => payment.invoiceID === invoiceID);
      if (paymentID) filteredPayments = filteredPayments.filter((payment) => payment.paymentID === paymentID);
      setFilteredPayments(filteredPayments);
    }
  }, [paymentID, payments, clientID, jobID, invoiceID, setFilteredPayments]);

  return (
    <PaymentsContext.Provider
      value={{
        payments,
        loading,
        error,
        refreshPaymentsList,
        createNewPayment,
        updateExistingPayment,
        deleteExistingPayment,
        selectedPayment,
        setSelectedPayment,
        filteredPayments,
        setFilteredPayments,
      }}
    >
      {loading ? <Loading /> : children}
    </PaymentsContext.Provider>
  );
}
